import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component';

import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ProfileAuthenticationComponent } from './components/pages/profile-authentication/profile-authentication.component';
import { LoginComponent } from './components/pages/login/login.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component';

import { ReadComponent } from './components/products/read/read.component';
import { DetailComponent } from './components/products/detail/detail.component';
import { ValiditaAttestatoComponent } from './components/pages/validita-attestato/validita-attestato';
import { Eipass } from './components/pages/eipass/eipass';
import { Pekit } from './components/pages/pekit/pekit';
import { LIM } from './components/pages/lim/lim';
import { Inglese } from './components/pages/inglese/inglese';
import { Coding } from './components/pages/coding/coding';
import { Tablet } from './components/pages/tablet/tablet';
import { ParGol } from './components/pages/pargol/par-gol';
import { GestioneAutonoma } from './components/pages/gestione-autonoma/gestione-autonoma';
import { JitsiComponent } from './jitsi/jitsi.component';

import { PersonaleATA } from './components/pages/personale-ata/personale-ata';

const routes: Routes = [
    //{path: '', component: LoginComponent},
    {path: '', component: ElearningSchoolComponent},
    {path: '', component: JitsiComponent},
    //Interfacce con API PHP
    {path: 'elenco-corsi', component: ReadComponent},
    {path: 'elenco-corsi/:id', component: ReadComponent},
    {path: 'dettaglio-corso/:id', component: DetailComponent},
    {path: 'validita-attestati', component: ValiditaAttestatoComponent},
    {path: 'eipass', component: Eipass},
    {path: 'par-gol', component: ParGol},
    {path: 'pekit', component: Pekit},
    {path: 'lim', component: LIM},
    {path: 'inglese', component: Inglese},
    {path: 'coding', component: Coding},
    {path: 'tablet', component: Tablet},
    {path: 'corsisti/:id', component: GestioneAutonoma},

    {path: 'personale-ata-2024', component: PersonaleATA},

    {path: 'learning-management', component: LearningManagementComponent},
    {path: 'reset-password', component: ProfileAuthenticationComponent},
    {path: 'login', component: LoginComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-of-service', component: TermsOfServiceComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'contatti', component: ContactUsComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
