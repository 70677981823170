import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CrudService {
    public url = environment.web_api_url_base;
    constructor(private http: HttpClient) { }

    getAll(params): Observable<any> {
        return this.http.get(this.url + 'corso.php', { params });
    }

    api_getLoginClientAPP(codiceCliente) {
        var formData: any = new FormData();
        formData.append("type", 'get_LoginAppClient');
        formData.append("codiceCliente", codiceCliente);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_modificaPassword(idCorsista, newPassword) {
        var formData: any = new FormData();
        formData.append("type", 'cambioPassword');
        formData.append("idCorsista", idCorsista);
        formData.append("newPassword", newPassword);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_modificaPasswordEmail(username, email) {
        var formData: any = new FormData();
        formData.append("type", 'cambioPasswordEmail');
        formData.append("username", username);
        formData.append("email", email);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoCorsi(pag, idCorsista, sottoCategoria, queryFind, idCorsistaLogin){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoCorsi');
        formData.append("nPag", pag);
        //idCorsista per i miei corsi
        formData.append("idCorsista", idCorsista);
        //idCorsista di DEFAULT, cioè se ha fatto il login, per la visualizzazione dei prezzi
        formData.append("idCorsistaDefault", idCorsistaLogin);
        formData.append("sottoCategoria", sottoCategoria);
        formData.append("queryFind", queryFind);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoCorsiInHome(idCorsista){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoCorsiInHome');
        formData.append("idCorsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_SettingsValue(settingName){
        var formData: any = new FormData();
        formData.append("type", 'get_SettingsValue');
        formData.append("settingName", settingName);
        return this.http.post(this.url + 'corso.php', formData);
    }


    api_get_ElencoSottoCategorie(){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoSottoCategorie');
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoSottoCategorieAll(){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoSottoCategorieAll');
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoSottoCategorieWeb(){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoSottoCategorieWeb');
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoSottoCategorieWeb_Secondary(){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoSottoCategorieWeb_Secondary');
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_getDetailTempoConnessioneDay(arrayDetail) {
        var formData: any = new FormData();
        formData.append("type", 'getDetailTempoConnessioneDay');
        formData.append("idCorso", arrayDetail['idCorso']);
        formData.append("idCorsista", arrayDetail['idCorsista']);
        formData.append("data", arrayDetail['dataDay']);
        return this.http.post(this.url + 'corso.php', formData);
    }


    api_get_ElencoPropostaCorsi(){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoPropostaCorsi');
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoCorsiPartner(idPartner){
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoCorsiPartner');
        formData.append("idPartner", idPartner);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_PartnerQRCODE(qr_code){
        var formData: any = new FormData();
        formData.append("type", 'getDetailPartnerFromQRCODE');
        formData.append("qr_code", qr_code);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_registraPagamento(arrayDetail){
        var formData: any = new FormData();
        formData.append("type", 'registraPagamento');

        formData.append("idCorso", arrayDetail['idCorso']);
        formData.append("idCorsista", arrayDetail['idCorsista']);
        formData.append("idPartner", arrayDetail['idPartner']);

        formData.append("idTransiction", arrayDetail['idTransiction']);
        formData.append("metodo", arrayDetail['metodo']);
        formData.append("status", arrayDetail['status']);
        formData.append("payer_id", arrayDetail['payer_id']);
        formData.append("payer_nome", arrayDetail['payer_nome']);
        formData.append("payer_cognome", arrayDetail['payer_cognome']);
        formData.append("payer_email", arrayDetail['payer_email']);
        formData.append("purchase_id", arrayDetail['purchase_id']);
        formData.append("purchase_importo_valuta", arrayDetail['purchase_importo_valuta']);
        formData.append("purchase_importo", arrayDetail['purchase_importo']);
        formData.append("payment_id", arrayDetail['payment_id']);
        return this.http.post(this.url + 'corso.php', formData);
    }


    api_addCorsistaInAula(idCorsista, idPartner, idCorso){
        var formData: any = new FormData();
        formData.append("type", 'addInAulaCorsista');
        formData.append("idCorso", idCorso);
        formData.append("idPartner", idPartner);
        formData.append("idCorsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_getDetailCorsista(idCorsista){
        var formData: any = new FormData();
        formData.append("type", 'getDetailCorsista');
        formData.append("idCorsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_setTImeoutCorsista(idCorsista, idCorsoSvolgimento){
        var formData: any = new FormData();
        formData.append("type", 'setTImeoutCorsista');
        formData.append("idCorsista", idCorsista);
        formData.append("idCorsoSvolgimento", idCorsoSvolgimento);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_getDetailUser(idUser){
        var formData: any = new FormData();
        formData.append("type", 'getDetailUser');
        formData.append("idUser", idUser);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_DetailCorso(id, idCorsista, isInLive){
        var formData: any = new FormData();
        formData.append("type", 'get_DetailCorsoTag');
        formData.append("isInLive", isInLive);
        formData.append("id_corso", id);
        formData.append("id_corsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_refreshModuloComplete(id, idCorsista){
        var formData: any = new FormData();
        formData.append("type", 'get_refreshModuloComplete');
        formData.append("id_corso", id);
        formData.append("id_corsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_DetailPartner(idPartner){
        var formData: any = new FormData();
        formData.append("type", 'getDetailPartner');
        formData.append("idPartner", idPartner);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_Login(username, password) {
        var formData: any = new FormData();
        formData.append("type", 'get_Login');
        formData.append("username", username);
        formData.append("password", password);
        return this.http.post(this.url + 'corso.php', formData);
    }

    salvaCheckTime(dateTimeInizio, dateTimeConfermaPresenza, isPresent, idCorsoSvolgimento, idCorsista, idCorso) {
        var formData: any = new FormData();
        formData.append("type", 'salvaCheckTime');
        formData.append("idCorsoSvolgimento", idCorsoSvolgimento);
        formData.append("idCorsista", idCorsista);
        formData.append("idCorso", idCorso);
        formData.append("dateTimeInizio", dateTimeInizio);
        formData.append("dateTimeConfermaPresenza", dateTimeConfermaPresenza);
        formData.append("isPresent", isPresent);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoAulaCorso(idCorso, idCorsista) {
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoAulaCorso');
        formData.append("id_corso", idCorso);
        formData.append("id_corsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_ElencoAulaCorsoTag(tag, idCorsista) {
        var formData: any = new FormData();
        formData.append("type", 'get_ElencoAulaCorsoTag');
        formData.append("tag", tag);
        formData.append("id_corsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_get_DetailQuiz(idCorso, idCorsista, idCorsoSvolgimento) {
        var formData: any = new FormData();
        formData.append("type", 'get_DetailQuiz');
        formData.append("id_corso", idCorso);
        formData.append("id_corsista", idCorsista);
        formData.append("id_corso_svolgimento", idCorsoSvolgimento);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_getElencoSessioniEsameFrontEnd(idCorsista) {
        var formData: any = new FormData();
        formData.append("type", 'getElencoSessioniEsameFrontEnd');
        formData.append("id_corsista", idCorsista);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_getSessioneEsameCorsista(idCorsista, idCorso) {
        var formData: any = new FormData();
        formData.append("type", 'getSessioneEsameCorsista');
        formData.append("id_corsista", idCorsista);
        formData.append("id_corso", idCorso);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_prenotaSessioneEsame(arrayDetail) {
        var formData: any = new FormData();
        formData.append("type", 'prenotaSessioneEsame');
        formData.append("id_corso", arrayDetail['id_corso']);
        formData.append("id_corsista", arrayDetail['id_corsista']);
        formData.append("idEsameBody", arrayDetail['idEsameBody']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_eliminaCorsistaSessioneEsame(arrayDetail) {
        var formData: any = new FormData();
        formData.append("type", 'eliminaCorsistaSessioneEsame');
        formData.append("idEsameCorsisti", arrayDetail['idEsameCorsisti']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_verifica_quiz(arrayDetail) {
        var formData: any = new FormData();
        formData.append("type", 'get_verificaQuiz');
        formData.append("listaRisposte", JSON.stringify(arrayDetail));
        return this.http.post(this.url + 'corso.php', formData);
    }

    api_GeneraAttestato(details) {
        var formData: any = new FormData();
        formData.append("type", 'get_InfoForCertificate');
        formData.append("id_corso", details['id_corso']);
        formData.append("id_corsista", details['id_corsista']);
        formData.append("id_corso_svolgimento", details['id_corso_svolgimento']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    set_entraInAulaCorsista(details) {
        var formData: any = new FormData();
        formData.append("type", 'set_entraInAulaCorsista');
        formData.append("idCorsista", details['idCorsista']);
        formData.append("id_corso_svolgimento", details['idCorsoSvolgimento']);
        formData.append("id_corso_header", details['idCorsoHeader']);
        formData.append("durata", details['durata']);
        formData.append("nome_aula", details['nome_aula']);
        formData.append("link_aula", details['link_aula']);
        formData.append("data_inizio", details['dataInizio']);
        formData.append("data_fine", details['dataFine']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    set_entraInMeetEsame(details) {
        var formData: any = new FormData();
        formData.append("type", 'set_entraInMeetEsame');
        formData.append("idCorsista", details['idCorsista']);
        formData.append("idEsameBody", details['idEsameBody']);
        formData.append("tipologia", details['tipologia']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    set_dateTimeInizioEsame(details) {
        var formData: any = new FormData();
        formData.append("type", 'set_dateTimeInizioEsame');
        formData.append("idCorsista", details['idCorsista']);
        formData.append("idEsameBody", details['idEsameBody']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    sent_Email(nome, email, telefono, messaggio, sceltaCorso) {
        var formData: any = new FormData();
        formData.append("nome", nome);
        formData.append("email", email);
        formData.append("telefono", telefono);
        formData.append("messaggio", messaggio);
        formData.append("sceltaCorso", sceltaCorso);
        return this.http.post(this.url + 'email.php', formData);
    }

    sent_IscrizioneForPartner(details) {
        let nome=details['nome'];
        let cognome=details['cognome'];
        let sesso=details['sesso'];
        let indirizzo=details['indirizzo'];
        let cap=details['cap'];
        let citta=details['citta'];
        let provincia=details['provincia'];
        let luogoNascita=details['luogoNascita'];
        let dataNascita=details['dataNascita'];
        let email=details['email'];
        let cellulare=details['cellulare'];
        let sceltaCorso=details['corsoScelto'];
        let fileAllegato=details['fileAllefato'];
        let idPartner=details['idPartner'];
        let cf=details['cf'];

        var formData: any = new FormData();
        formData.append("idPartner", idPartner);
        formData.append("nome", nome);
        formData.append("cognome", cognome);
        formData.append("sesso", sesso);
        formData.append("cf", cf);
        formData.append("indirizzo", indirizzo);
        formData.append("cap", cap);
        formData.append("citta", citta);
        formData.append("provincia", provincia);
        formData.append("luogoNascita", luogoNascita);
        formData.append("dataNascita", dataNascita);
        formData.append("email", email);
        formData.append("cellulare", cellulare);
        formData.append("sceltaCorso", sceltaCorso);
        formData.append("fileAllegato", fileAllegato);

        return this.http.post(this.url + 'email_iscirizioneCorsistaFromPartner.php', formData);
    }

    sent_confermaIscrizioneCorsista(details) {
        let nome=details['nome'];
        let cognome=details['cognome'];
        let nomeCorso=details['nomeCorso'];
        let cf=details['cf'];
        let email=details['email'];
        let idPartner=details['idPartner'];

        var formData: any = new FormData();
        formData.append("nome", nome);
        formData.append("cognome", cognome);
        formData.append("cf", cf);
        formData.append("nomeCorso", nomeCorso);
        formData.append("email", email);
        formData.append("idPartner", idPartner);

        return this.http.post(this.url + 'email_confermaIscirizioneCorsista.php', formData);
    }

    get_creaAccountCorsista(details) {
        let nome=details['nome'];
        let cognome=details['cognome'];
        let sesso=details['sesso'];
        let indirizzo=details['indirizzo'];
        let cap=details['cap'];
        let citta=details['citta'];
        let provincia=details['provincia'];
        let luogoNascita=details['luogoNascita'];
        let dataNascita=details['dataNascita'];
        let email=details['email'];
        let cellulare=details['cellulare'];
        let fileAllegato=details['fileAllefato'];
        let idPartner=details['idPartner'];
        let cf=details['cf'];

        var formData: any = new FormData();
        formData.append("type", 'get_creaAccountCorsista');
        formData.append("idPartner", idPartner);
        formData.append("nome", nome);
        formData.append("cognome", cognome);
        formData.append("sesso", sesso);
        formData.append("cf", cf);
        formData.append("indirizzo", indirizzo);
        formData.append("cap", cap);
        formData.append("citta", citta);
        formData.append("provincia", provincia);
        formData.append("luogoNascita", luogoNascita);
        formData.append("dataNascita", dataNascita);
        formData.append("email", email);
        formData.append("cellulare", cellulare);
        formData.append("fileAllegato", fileAllegato);

        return this.http.post(this.url + 'corso.php', formData);
    }

    getTimeVideoVisualizzato(idCorsista, idCorso, idPartner, idModuloBody, idModuloBodyParagrafo, isInLive) {
        var formData: any = new FormData();
        formData.append("type", 'getTimeVideoVisualizzato');
        formData.append("isInLive", isInLive);
        formData.append("idCorso", idCorso);
        formData.append("idCorsista", idCorsista);
        formData.append("idPartner", idPartner);
        formData.append("idModuloBody", idModuloBody);
        formData.append("idModuloBodyParagrafo", idModuloBodyParagrafo);
        return this.http.post(this.url + 'corso.php', formData);
    }



    salveTempoTrascorsoVideo(details) {
        var formData: any = new FormData();
        formData.append("type", 'salveTempoTrascorsoVideo');
        formData.append("isInLive", details['isInLive']);
        formData.append("idCorso", details['idCorso']);
        formData.append("idCorsoSvolgimento", details['idCorsoSvolgimento']);
        formData.append("idCorsista", details['idCorsista']);
        formData.append("idPartner", details['idPartner']);
        formData.append("idModuloBody", details['idModuloBody']);
        formData.append("idModuloBodyParagrafo", details['idModuloBodyParagrafo']);
        formData.append("tempoTrascorso", details['tempoTrascorso']);
        formData.append("isPDF", details['isPDF']);
        return this.http.post(this.url + 'corso.php', formData);
    }

    apiVerificaAttestato(details) {
        var formData: any = new FormData();
        formData.append("type", 'verifica-attestato');
        formData.append("nome", details['nome']);
        formData.append("cognome", details['cognome']);
        formData.append("data_nascita", details['data_nascita']);
        formData.append("cf", details['cf']);
        return this.http.post(this.url + 'corso.php', formData);
    }


    /*
    createProduct(data){
        return this.http.post(this.url + 'create.php', data);
    }

    updateProduct(data){
        return this.http.post(this.url + 'update.php', data);
    }

    deleteProduct(id){
        return this.http.get(this.url + 'delete.php?id=' + id);
    }
    */
}
