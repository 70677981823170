
<a class="whats-app" href="https://wa.me/{{numero_WhatsAppMessage}}" target="_blank">
    <i class="fa fa-whatsapp whats-app-float">
        <img id="imageWhatsApp" src="assets/img/whatsapp.png" alt="logo">
    </i>
</a>

<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" style="padding-top: 0px;" ngStickyNav>
    <div style="background-color: var(--mainColor); opacity: 1;" class="mb-2 headerRegione"></div>
    <div class="container-fluid">
        <div style="height: 45px; position: absolute; top: 6px;" class="mb-2">
            <h6 class="text-white ms-2 pt-1 headerEnteRegione">
                <a href="tel:+390692917932" class="info-link">
                    <i class='bx-fw bx-sm bx bx-phone' style="padding-top: 2px; color: #FFF;"></i>
                    <span class="text-white fw-bolder">(+39) 06-92917932</span>
                </a>
                <a href="tel:+393292137221" class="info-link ms-3">
                    <i class='bx-fw bx-sm bx bxl-whatsapp' style="padding-top: 2px; color: #FFF;"></i>
                    <span class="text-white fw-bolder">(+39) 329-2137221</span>
                </a>
                <a href="mailto: info@assoesercenti.it" class="info-link ms-3">
                    <i class='bx-fw bx-sm bx bx-mail-send' style="padding-top: 2px; color: #FFF;"></i>
                    <span class="text-white fw-bolder">info@assoesercenti.it</span>
                </a>
            </h6>
            <h6 class="text-white ms-2 pt-1 headerEnteRegioneMobile">
                <a href="tel:+390692917932" class="info-link d-block">
                    <i class='bx-fw bx-sm bx bx-phone' style="padding-top: 2px; color: #FFF;"></i>
                    <span class="text-white fw-bolder">(+39) 06-92917932</span>
                </a>
                <a href="mailto: info@assoesercenti.it" class="info-link d-block mt-1">
                    <i class='bx-fw bx-sm bx bx-mail-send' style="padding-top: 2px; color: #FFF;"></i>
                    <span class="text-white fw-bolder">info@assoesercenti.it</span>
                </a>
            </h6>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo_new1.png" alt="logo" style="max-width: 320px;"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item d-none"><a routerLink="/" class="nav-link">Home</a></li>
                    <li class="nav-item"><a href="elenco-corsi" class="nav-link">Corsi di Formazione <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-capitalize" *ngFor="let rowMenu of menuWeb"><a href="/elenco-corsi/{{rowMenu.tag}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{rowMenu.nome}}</a></li>
                            <!--
                            <li class="nav-item"><a href="/elenco-corsi/comune" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Comune</a></li>
                            <li class="nav-item d-none"><a href="/elenco-corsi/servizi-informatica" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi Informatici</a></li>
                            <li class="nav-item d-none"><a href="/elenco-corsi/servizi-distribuzione-commerciale" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi di Distribuzione Commerciale</a></li>
                            <li class="nav-item d-none"><a href="/elenco-corsi/servizi-persona" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi alla Persona</a></li>
                            <li class="nav-item"><a href="/elenco-corsi/sicurezza" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Corsi sulla Sicurezza</a></li>
                            -->
                        </ul>
                    </li>
                    <li class="nav-item megamenu d-none"><a href="/personale-ata-2024" class="nav-link">Personale ATA 2024 </a>
                    </li>
                    <li class="nav-item megamenu"><a href="/par-gol" class="nav-link">PAR GOL </a>
                    </li>
                    <li class="nav-item d-none"><a routerLink="" class="nav-link">Certificazioni <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-capitalize" *ngFor="let rowMenu of menuWeb_Secondary"><a href="/elenco-corsi/{{rowMenu.tag}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{rowMenu.nome}}</a></li>
                            <!--
                            <li class="nav-item"><a href="/elenco-corsi/eipass" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">EIPASS</a></li>
                            <li class="nav-item d-none"><a href="/pekit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pekit</a></li>
                            <li class="nav-item"><a href="/dettaglio-corso/dattilografia" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dattilografia</a></li>
                            <li class="nav-item d-none"><a href="/coding" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coding</a></li>
                            <li class="nav-item d-none"><a href="/lim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">LIM</a></li>
                            <li class="nav-item d-none"><a href="/tablet" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tablet</a></li>
                            <li class="nav-item">
                                <a href="/elenco-corsi/certificazioni-linguistiche" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                    Inglese
                                </a>
                            </li>
                            -->

                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/validita-attestati" class="nav-link">Validità Attestato</a>
                    </li>
                    <li class="nav-item"><a routerLink="/contatti" class="nav-link">Contatti</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li *ngIf="detailUser" class="nav-item"><a href="elenco-corsi/i-miei-corsi" class="nav-link text-uppercase fw-bolder" style="color: var(--mainColor);">I miei corsi - corsi acquistati</a>
                    </li>
                    <li *ngIf="detailUser" class="nav-item"><a (click)="logout()" class="nav-link text-danger text-uppercase fw-bolder">Effettua il Logout</a>
                    </li>
                    <li *ngIf="!detailUser" class="nav-item"><a href="login" class="nav-link text-uppercase fw-bolder" style="color: var(--mainColor);">Effettua il Login</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a  class="nav-link">Home</a>
                    </li>
                    <li class="nav-item"><a  class="nav-link">Corsi di Formazione <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-capitalize" *ngFor="let rowMenu of menuWeb"><a href="/elenco-corsi/{{rowMenu.tag}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{rowMenu.nome}}</a></li>
                            <!--
                            <li class="nav-item"><a href="/elenco-corsi/servizi-socio-sanitari" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi Socio Sanitari</a></li>
                            <li class="nav-item"><a href="/elenco-corsi/comune" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Comune</a></li>
                            <li class="nav-item d-none"><a href="/elenco-corsi/servizi-informatica" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi Informatici</a></li>
                            <li class="nav-item d-none"><a href="/elenco-corsi/servizi-distribuzione-commerciale" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi di Distribuzione Commerciale</a></li>
                            <li class="nav-item d-none"><a href="/elenco-corsi/servizi-persona" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area Servizi alla Persona</a></li>
                            <li class="nav-item"><a href="/elenco-corsi/sicurezza" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Corsi sulla Sicurezza</a></li>
                            -->
                        </ul>
                    </li>
                    <li class="nav-item megamenu d-none"><a href="/personale-ata-2024" class="nav-link">Personale ATA 2024 </a>
                    </li>
                    <li class="nav-item megamenu"><a href="/par-gol" class="nav-link">PAR GOL </a>
                    </li>
                    <li class="nav-item d-none"><a routerLink="" class="nav-link">Certificazioni <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-capitalize" *ngFor="let rowMenu of menuWeb_Secondary"><a href="/elenco-corsi/{{rowMenu.tag}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{rowMenu.nome}}</a></li>
                            <!--
                            <li class="nav-item"><a href="/elenco-corsi/eipass" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">EIPASS</a></li>
                            <li class="nav-item d-none"><a href="/pekit" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pekit</a></li>
                            <li class="nav-item"><a href="/dettaglio-corso/dattilografia" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dattilografia</a></li>
                            <li class="nav-item d-none"><a href="/coding" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coding</a></li>
                            <li class="nav-item d-none"><a href="/lim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">LIM</a></li>
                            <li class="nav-item d-none"><a href="/tablet" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tablet</a></li>
                            <li class="nav-item">
                                <a href="/elenco-corsi/certificazioni-linguistiche" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                    Inglese
                                </a>
                            </li>
                            -->
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/validita-attestati"  class="nav-link">Validità Attestato</a>
                    </li>
                    <li class="nav-item"><a routerLink="/contatti" class="nav-link">Contatti</a>
                    </li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item d-none">
                    <div id="imgBtnCart" class="cart-btn">
                        <a routerLink="/cart"><i class='flaticon-shopping-cart'></i><span>3</span></a>
                    </div>
                </div>
                <div class="text-right headerSocial d-none" style="position:absolute; top:10px">
                    <span class="text-white me-2">seguici</span>
                    <a href="#" target="_blank">
                        <i class='bx-fw bx-sm bx bxl-facebook-square text-white' style="padding-top: 2px;"></i>
                    </a>
                    <a href="h#" target="_blank">
                        <i class='bx-fw bx-sm bx bxl-instagram-alt text-white' style="padding-top: 2px;"></i>
                    </a>
                </div>
                <div class="option-item">
                    <a *ngIf="!detailUser" id="btnLogin" href="login" class="default-btn" style="cursor: pointer;"><i class="flaticon-user"></i>
                        Login<span></span>
                    </a>
                    <a *ngIf="detailUser" id="btnLogin" href="elenco-corsi/i-miei-corsi" class="default-btn" style="cursor: pointer; padding-left: 15px; padding-right: 15px">
                        <i class="flaticon-agenda d-none"></i>
                        I miei corsi<span></span>
                    </a>
                    <a *ngIf="detailUser" id="btnLogin" class="default-btn ms-1" (click)="logout()" style="padding-left: 15px; background-color: var(--bs-danger); cursor: pointer; padding-right: 15px">
                        Esci
                    </a>
                </div>

                </div>
        </nav>
    </div>
</div>
