<input *ngIf="this.detailUser" type="hidden" id="serverMeet" value="{{this.arrayLive.serverMeet}}">
<input *ngIf="this.detailUser" type="hidden" id="idCorsistaMeet" value="{{this.detailUser['id_corsista']}}">
<input *ngIf="this.detailUser" type="hidden" id="nomeCognomeMeet" value="{{this.detailUser['cognome']+' '+this.detailUser['nome']}}">
<input *ngIf="this.detailUser" type="hidden" id="emailCorsistaMeet" value="{{this.detailUser['email']}}">
<input *ngIf="this.detailUser" type="hidden" id="idCorsoSvolgimento" value="{{this.arrayLive['id_corso_svolgimento']}}">
<input *ngIf="this.detailUser" type="hidden" id="nomeAula" value="{{this.arrayLive['nome_aula']}}">
<input *ngIf="this.detailUser" type="hidden" id="tokenMeet" value="{{this.detallCorso.tokenMeet}}">
<input *ngIf="this.arrayLive" type="hidden" id="abilitaVideocamera" value="{{this.arrayLive.abilitaVideocamera}}">
<input *ngIf="this.arrayLive" type="hidden" id="canShowExtraControls" value="{{this.arrayLive.canShowExtraControls}}">

<input type="hidden" id="idCorsoHeader" value="{{this.arrayLive['id_corso_header']}}">
<input type="hidden" id="durata" value="{{this.arrayLive['durata']}}">
<input type="hidden" id="linkAula" value="{{this.arrayLive['link_aula']}}">
<input type="hidden" id="dataOra" value="{{this.arrayLive['data_ora']}}">
<input type="hidden" id="dataOraFineFull" value="{{this.arrayLive['data_ora_fine_full']}}">

<div *ngIf="showMeetPreload">
    <div style="width: 100%; height: 100%; position: fixed; z-index: 999; top: 0px; background-color: rgba(255, 255, 255, 0.85);">
        <div style="margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);" align="center">
        <img src="assets/img/loading_call.png" style="width: 120px;">
        <div>
            <h1 class="font-weight-black">Stai per entrare in aula ...</h1>
        </div>
        <button class="default-btn" style="padding-left: 15px; padding-right: 15px; background-color: var(--bs-danger); cursor: pointer;">
            Annulla</button>
        </div>
    </div>
</div>
<div *ngIf="showMeet"><app-jitsi></app-jitsi></div>
<div *ngIf="showTestValutazione==true" style="position: fixed; left: 0; background: rgba(51,51,51,0.7); z-index: 9; overflow-y: auto; width: 100%;">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-sm-12 col-lg-8" style="height: 100vh;">
            <div class="paddingTestValutazione" style="background-color: #FFF; padding-bottom: 160px!important;">
                <span
                    style="background-color: var(--bs-warning); border-radius: 3px; font-size: 10pt; padding: 3px 5px 3px 5px;"
                    class="fw-bolder">
                    TEST DI VALUTAZIONE
                </span>
                <h3 class="mt-1 mb-4 fw-bolder">{{moduloUC_TestValutazioneSelected.name}}</h3>
                <div class="mb-4" *ngFor="let detailBody of moduloUC_TestValutazioneSelected.quizBody; index as indexQuiz;">
                    <span>
                        <span class="courses-name fw-bolder fs-5" style="color:var(--mainColor);">{{(indexQuiz+1)+') '+detailBody['domanda']['name']}}</span>
                        <ul class="mb-0" style="list-style-type:lower-alpha;">
                            <li *ngFor="let detailElencoRisposte of detailBody.elenco_risposte; index as indexRisposte;">
                                <div class="align-items-center ms-2">
                                    <input type="radio" name="{{detailBody['domanda']['id_domanda']}}" value="{{detailElencoRisposte['risposta']['id_risposta']}}" />
                                    <span class="courses-name w-75 mt-1 ms-2" style="font-weight: 600;" [ngStyle]="(detailElencoRisposte['isCorrect']==0) ? {'color': 'var(--blackColor)'} : detailUser['assistenza']==false ? {'color': 'var(--blackColor)'} : {'color': 'var(--bs-success)'}">
                                            {{detailElencoRisposte['risposta']['name']}}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </span>
                </div>
                <a target="_blank" [attr.href]="null" (click)="verificaTestValutazione(moduloUC_TestValutazioneSelected.id_quiz_header)" class="default-btn mt-5 ps-4" style="cursor:pointer">
                   <img src="assets/img/test_valutazione.png" width="26px" class="me-1">
                    Verifica le tue risposte<span></span>
                </a>
                <a target="_blank" [attr.href]="null" (click)="chiudiTestValutazione()" class="default-btn mt-5 ps-4 ms-3"
                    style="cursor:pointer; background-color: var(--bs-red); height: 46px;">
                     Chiudi<span></span>
                 </a>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
</div>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul class="mt-3">
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                <li>{{detallCorso['titolo']}}</li>
            </ul>
            <h2 class="mt-5">{{detallCorso['titolo']}}</h2>
            <div class="rating courses-meta d-none">
                <span class="status">{{detallCorso['categoria']}}</span>
            </div>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img *ngIf="detallCorso['image']!=''" src="{{urlImageHD+detallCorso['image']}}"  style="max-height:550px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <!--<div style="width: 100%; height:100%; position:absolute; z-index:999; background-color: white;">Caricamento del corso...</div>-->

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset #staticTabs>
                        <ngx-tab id="tabs1" tabTitle="Obiettivi del corso">
                            <div class="courses-overview">
                                <h3 class="d-none">Programma del corso</h3>
                                <div id="programmaHTML"></div>
                            </div>
                        </ngx-tab>
                        <ngx-tab *ngIf="nModuliUC" #tabs2 tabTitle="Moduli UC">
                            <div class="courses-curriculum mb-3" *ngFor="let moduloUC_dettaglio of moduliUC; index as indexModulo;">
                                <h6 *ngIf="moduloUC_dettaglio['isLocked']==1" class="text-danger fw-bolder"><i class="\"></i> Non hai i permessi per visualizzare questo modulo</h6>
                                <h3>{{moduloUC_dettaglio['titolo']}}</h3>
                                <ul *ngFor="let moduloUC_Paragrafo of moduloUC_dettaglio['paragrafi']; index as indexParagrafo;">
                                    <li class="mb-5">
                                        <span class="courses-name fw-bolder fs-5" style="color:var(--mainColor)">{{moduloUC_Paragrafo['titolo']}}</span>
                                        <ul *ngFor="let moduloUC_SottoParagrafo of moduloUC_Paragrafo['sotto-paragrafi']; index as indexSottoParagrafo;">
                                            <li *ngIf="!moduloUC_SottoParagrafo['video']">
                                                <a
                                                    class="popup-youtube d-flex justify-content-between align-items-center">
                                                    <span class="courses-name w-75 courses-meta" style="text-align: left;">
                                                        {{moduloUC_SottoParagrafo['titolo']}}
                                                        <span *ngIf="moduloUC_SottoParagrafo['cfu']>0" class="status"> {{moduloUC_SottoParagrafo['cfu']}} CFU</span>
                                                    </span>
                                                </a>
                                            </li>

                                            <li *ngIf="moduloUC_SottoParagrafo['video']">
                                                <a *ngIf="((moduloUC_SottoParagrafo['video'].includes('.pdf') && moduloUC_dettaglio['isLocked']==0) && (isEnabledUser && lockModuliUC==0))" target="_blank" [href]="urlVideoCorso+moduloUC_SottoParagrafo['video']" (click)="downloadPDF_ModuloUC(moduloUC_SottoParagrafo['id_modulo_body'], moduloUC_SottoParagrafo['id_modulo_body_paragrafo']);"
                                                    class="popup-youtube d-flex justify-content-between align-items-center">
                                                    <span class="courses-name w-75 courses-meta" style="text-align: left;">
                                                        {{moduloUC_SottoParagrafo['titolo']}}
                                                        <span *ngIf="moduloUC_SottoParagrafo['cfu']>0" class="status"> {{moduloUC_SottoParagrafo['cfu']}} CFU</span>
                                                    </span>
                                                    <div class="courses-meta" style="min-width:280px">
                                                        <span [class]="moduloUC_SottoParagrafo['haveReadPDF']==1 ? 'questions me-0' : 'duration'" style="text-transform: uppercase;">{{moduloUC_SottoParagrafo['haveReadPDF']==1 ? 'visualizzato' : 'non visualizzato'}}</span>
                                                    </div>
                                                </a>
                                                <a *ngIf="moduloUC_SottoParagrafo['video'].includes('.pdf') && (moduloUC_dettaglio['isLocked']==1 || lockModuliUC==1 || !isEnabledUser) " style="cursor:pointer" (click)="noFADAttiva(moduloUC_dettaglio['isLocked'])"
                                                    class="popup-youtube d-flex justify-content-between align-items-center">
                                                    <span class="courses-name w-75 courses-meta" style="text-align: left;">
                                                        {{moduloUC_SottoParagrafo['titolo']}}
                                                        <span *ngIf="moduloUC_SottoParagrafo['cfu']>0" class="status"> {{moduloUC_SottoParagrafo['cfu']}} CFU</span>
                                                    </span>
                                                    <div class="courses-meta" style="min-width:280px">
                                                        <span [class]="moduloUC_SottoParagrafo['haveReadPDF']==1 ? 'questions me-0' : 'duration'" style="text-transform: uppercase;">{{moduloUC_SottoParagrafo['haveReadPDF']==1 ? 'visualizzato' : 'non visualizzato'}}</span>
                                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                                    </div>
                                                </a>
                                                <a *ngIf="!moduloUC_SottoParagrafo['video'].includes('.pdf')" href="javascript:;"  (click)="(isEnabledUser && isInLive!=2 && (lockModuliUC==0) && detailQuiz['isSuperatoQuiz']==0 && moduloUC_dettaglio['isLocked']==0) ? playVideo(urlVideoCorso+moduloUC_SottoParagrafo['video'],moduloUC_SottoParagrafo['titolo'], moduloUC_dettaglio['id_corso'], moduloUC_dettaglio['id_partner'], moduloUC_SottoParagrafo['id_modulo_body'], moduloUC_SottoParagrafo['id_modulo_body_paragrafo'], moduloUC_SottoParagrafo['tempoTrascorso'], moduloUC_SottoParagrafo['durata'], moduloUC_dettaglio['isLocked']) : noFADAttiva(moduloUC_dettaglio['isLocked'])"
                                                    class="popup-youtube d-flex justify-content-between align-items-center">
                                                    <span class="courses-name w-75 courses-meta" style="text-align: left;">
                                                        {{moduloUC_SottoParagrafo['titolo']}}
                                                        <span *ngIf="moduloUC_SottoParagrafo['cfu']>0" class="status"> {{moduloUC_SottoParagrafo['cfu']}} CFU</span>
                                                    </span>
                                                    <div class="courses-meta" style="min-width:280px">
                                                        <span id="video_{{moduloUC_SottoParagrafo['id_modulo_body_paragrafo']}}" [class]="moduloUC_SottoParagrafo['tempoTrascorso_formattata'] ? '' : 'd-none'" class="questions me-1" style="max-width: 80px;" [title]="moduloUC_SottoParagrafo['tempoRestante']<=0 ? 'Visualizzato interamente' : 'Tempo del video che hai visualizzato'">{{moduloUC_SottoParagrafo['tempoTrascorso_formattata']}}</span>
                                                        <span *ngIf="moduloUC_dettaglio['isLocked']!=2" class="duration" style="max-width: 80px;" title="Durata del video">{{moduloUC_SottoParagrafo['durata_formattata']}}</span>
                                                        <span *ngIf="moduloUC_dettaglio['isLocked']==2" class="duration" style="max-width: 80px; background-color:rgba(255, 193, 7, 0.25); color: #ffc107;" title="Durata del video">{{moduloUC_SottoParagrafo['durata_formattata']}}</span>
                                                        <span *ngIf="((moduloUC_SottoParagrafo['position'] == 1) && (indexParagrafo==0) && (indexModulo==0))" class="status">Anteprima</span>
                                                        <span *ngIf="(!isEnabledUser || lockModuliUC==1 || detailQuiz['isSuperatoQuiz']==1 || moduloUC_dettaglio['isLocked']==1)" class="status locked"><i class="flaticon-password"></i></span>
                                                        <span *ngIf="(!isEnabledUser || lockModuliUC==1 || detailQuiz['isSuperatoQuiz']==1 || moduloUC_dettaglio['isLocked']==2)" class="status locked" style="color: var(--bs-warning)"><i class="flaticon-time-left"></i></span>
                                                    </div>
                                                </a>
                                            </li>
                                            <div *ngIf="moduloUC_SottoParagrafo['linkografia']?.length>0" class="mt-2 mb-4 ms-4">
                                                <span class="courses-name fw-bolder fs-5">Linkografia</span>
                                                <ul *ngFor="let moduloUC_Linkografia of moduloUC_SottoParagrafo['linkografia']; index as indexLinkografia;">
                                                    <li>
                                                        <span (click)="openLinkografia(moduloUC_Linkografia['link'])" class="fs-6" style="color:var(--bs-blue); cursor:pointer">{{moduloUC_Linkografia['titolo']}};</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ul>
                                   </li>
                                </ul>
                                <div *ngFor="let uc_TestValutazione of moduloUC_TestValutazione; index as indexTestModulo;">
                                    <span *ngIf="(indexModulo+1)==uc_TestValutazione.position"
                                          style="background-color: var(--bs-warning); border-radius: 3px; font-size: 10pt; padding: 3px 5px 3px 5px;"
                                          class="fw-bolder">
                                          <span *ngIf="lockModuliUC==1 || !isEnabledUser || (uc_TestValutazione.canEnableTestValutazione==0)" class="status locked"><i class="flaticon-password"></i></span>
                                          TEST DI VALUTAZIONE
                                    </span>
                                    <a *ngIf="(((indexModulo+1)==uc_TestValutazione.position) && (uc_TestValutazione.isSuperato==0 || uc_TestValutazione.isSuperato==1))" target="_blank" [attr.href]="" (click)="stampaTestValutazione(uc_TestValutazione.id_quiz_header);"
                                        style="background-color: #e3f1f2; color: #2dbbc4; border-radius: 3px; font-size: 10pt; padding: 3px 5px 3px 5px; cursor: pointer;"
                                        class="fw-bolder ms-2">
                                        <img src="assets/img/printer.png" width="16px">
                                        VISUALIZZA I TEST EFFETTUATI
                                    </a>
                                    <h3 class="mt-1 paddingTestValutazioneTitolo" *ngIf="(indexModulo+1)==uc_TestValutazione.position">{{uc_TestValutazione.name}}</h3>
                                    <a *ngIf="((((indexModulo+1)==uc_TestValutazione.position) && moduloUC_dettaglio['isLocked']==0 && lockModuliUC==0 && isEnabledUser && (uc_TestValutazione.canEnableTestValutazione==1)) || (isEnabledUser && (uc_TestValutazione.canEnableTestValutazione==1 && ((indexModulo+1)==uc_TestValutazione.position) && (uc_TestValutazione.data_time_inizio))))" target="_blank" [attr.href]="null"
                                        (click)="apriTestValutazione(indexTestModulo);"
                                        class="btnEseguiVerifica default-btn ps-4">
                                        <img src="assets/img/test_valutazione.png" width="26px" class="me-1">
                                         Esegui la verifica<span></span>
                                     </a>

                                     <a *ngIf="(((indexModulo+1)==uc_TestValutazione.position) && moduloUC_dettaglio['isLocked']==1)" class="btnEseguiVerifica default-btn ps-4 btn-danger" style="background-color: var(--bs-red);">
                                        <img src="assets/img/test_valutazione.png" width="26px" class="me-1">
                                         Test Bloccato<span></span>
                                     </a>
                                </div>
                            </div>
                        </ngx-tab>
                        <ngx-tab *ngIf="materialeDidattico?.length>0" #tabs3 tabTitle="Materiale Didattico">
                            <div class="courses-curriculum">
                                <h3>Materiale Didattico</h3>
                                <ul *ngFor="let materialeDidattico_Dettaglio of materialeDidattico; index as indexMateriale;">
                                    <li *ngIf="materialeDidattico_Dettaglio.showOnlyisPaid==0" class="mb-3">
                                        <a target="_blank" [attr.href]="materialeDidatticoPath+materialeDidattico_Dettaglio['materiale']" class="d-flex justify-content-between align-items-center" style="cursor:pointer">
                                            <span class="courses-name fw-bolder fs-7" style="color:var(--mainColor)">{{materialeDidattico_Dettaglio['name']}}</span>
                                        </a>
                                   </li>
                                   <li *ngIf="isEnabledUser && materialeDidattico_Dettaglio.showOnlyisPaid==1" class="mb-3">
                                    <a target="_blank" [attr.href]="materialeDidatticoPath+materialeDidattico_Dettaglio['materiale']" class="d-flex justify-content-between align-items-center" style="cursor:pointer">
                                        <span class="courses-name fw-bolder fs-7" style="color:var(--mainColor)">{{materialeDidattico_Dettaglio['name']}}</span>
                                    </a>
                               </li>
                                </ul>
                            </div>
                        </ngx-tab>
                        <ngx-tab *ngIf="isEnabledUser && elencoDataCorso[0]['durata']>0 && canShowElencoDate" #tabs4 tabTitle="Elenco Date">
                            <div class="courses-curriculum">
                                <h3>Elenco delle date per lo svolgimento del corso</h3>
                                <ul *ngFor="let elencoDate of elencoDataCorso; index as indexElencoDate;">
                                    <li [ngClass]="elencoDate['limita_moduloUC']==1 ? 'mb-3' : 'mb-5'">
                                        <a target="_blank" [attr.href]="elencoDate['isLive']==1 ? elencoDate['link_aula'] : null" (click)="elencoDate['isLive']==1 ? entraInAula() : null" class="d-flex justify-content-between align-items-center" style="cursor:pointer">
                                        <span class="courses-name fw-bolder fs-7" [ngStyle]="elencoDate['isLive']==1 ? {'color': 'var(--secondaryColor)'} : {'color': 'var(--optionalColor)'}">{{elencoDate['data_estesa']}}
                                            <span *ngIf="(elencoDate['isLive'] == 1)" class="courses-name fw-normal"
                                                [ngStyle]="elencoDate['isLive'] ? {'color': 'var(--secondaryColor)'} : {'color': 'var(--optionalColor)'}"
                                                style="font-size: 10pt;">({{elencoDate['ora_formattata']}} ÷ {{elencoDate['data_ora_fine']}})</span>
                                        </span>
                                        <div class="courses-meta" style="min-width:200px">
                                            <span *ngIf="(elencoDate['isLive'] == 1)" class="questions text-uppercase">AULA ATTIVA</span>
                                            <span *ngIf="(elencoDate['isLive'] == 2)" class="questions text-uppercase"
                                                style="background-color: var(--bs-red); color:white">TERMINATA ALLE {{elencoDate['data_ora_fine']}}</span>
                                            <span *ngIf="(elencoDate['isLive'] == 5)" class="questions text-uppercase"
                                                style="background-color: var(--bs-red); color:white">NON ANCORA INIZIATA</span>
                                            <span *ngIf="(elencoDate['isLive'] == 0)" class="duration text-uppercase ms-2">AULA NON ATTIVA</span>
                                            <span *ngIf="(elencoDate['isLive'] != 1)" class="duration text-uppercase ms-2">
                                                {{elencoDate['nome_aula']}}. {{elencoDate['ora_formattata']}} ÷ {{elencoDate['data_ora_fine']}}
                                            </span>
                                            <span *ngIf="(elencoDate['nome_aula'])" class="status text-uppercase ms-2 d-none">{{elencoDate['nome_aula']}}</span>
                                            <span *ngIf="(elencoDate['limita_moduloUC']==1)" class="status text-uppercase ms-2">FAD</span>
                                            <span *ngIf="(elencoDate['limita_moduloUC']==0)" class="status text-uppercase ms-2">DAD</span>
                                        </div>
                                        </a>
                                        <div class="d-flex flex-column">
                                        <span *ngIf="elencoDate['totaleConnessioneGiornoFormattato']" class="ms-4 fs-7">
                                            durata della connessione: <span class="fw-bolder">{{elencoDate['totaleConnessioneGiornoFormattato']}}</span>
                                        </span>
                                        <span *ngIf="elencoDate['checkIsPresente']['date_create'] && elencoDate['limita_moduloUC']==0" class="ms-4 fs-7">
                                            accesso in aula alle ore: <span class="fw-bolder">{{elencoDate['checkIsPresente']['date_create'] | date: 'HH:mm'}}</span>
                                            <span *ngIf="elencoDate['checkIsPresente']['date_create_uscita'] && elencoDate['limita_moduloUC']==0">
                                                ÷ uscito alle ore: <span class="fw-bolder">{{elencoDate['checkIsPresente']['date_create_uscita'] | date: 'HH:mm'}}</span>
                                            </span>
                                        </span>
                                        <span *ngIf="elencoDate['checkIsPresente']['date_create'] && elencoDate['limita_moduloUC']==1" class="ms-4 fs-7">
                                            prima connessione alle ore: <span class="fw-bolder">{{elencoDate['checkIsPresente']['date_create'] | date: 'HH:mm'}}</span>
                                            <span *ngIf="elencoDate['checkIsPresente']['date_create_uscita'] && elencoDate['limita_moduloUC']==1">
                                                ÷ ultima connessione alle ore: <span class="fw-bolder">{{elencoDate['checkIsPresente']['date_create_uscita'] | date: 'HH:mm'}}</span>
                                            </span>
                                        </span>
                                        <span *ngIf="!elencoDate['checkIsPresente']['date_create'] && elencoDate['isLive']!=1" class="ms-4 fs-7">
                                            nessun accesso in aula effettuato, <b><u>ASSENTE!</u></b>
                                        </span>
                                        <span *ngIf="!elencoDate['checkIsPresente']['date_create'] && elencoDate['isLive']==1" class="ms-4 fs-7">
                                            non ha ancora effettuato l'accesso in aula, <b><u>NON IN AULA!</u></b>
                                        </span>
                                        </div>
                                   </li>
                                    <div *ngIf="(elencoDate['limita_moduloUC']==1)" class="faq-area mb-5">
                                        <div class="container">
                                            <div class="faq-accordion">
                                                <accordion #pageAccordion closeOthers="1">
                                                    <accordion-group heading="+ dettaglio connessione" (click)="dettaglioConnessioniVideo(this.idCorsoGlobal, elencoDate['id_corso_svolgimento'], elencoDate['data_ora'], this.detailUser['id_corsista'])">
                                                        <div id="accordion_{{elencoDate['id_corso_svolgimento']}}">

                                                        </div>
                                                    </accordion-group>
                                                </accordion>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </ngx-tab>

                        <ngx-tab #tabs5 *ngIf="sessioneEsame?.length && isEnabledUser && detailQuiz['isDataLimitata']==0 && sessioneEsameCorsista.isInLiveEsameCorsista!=1 && detailQuiz['isSuperatoQuiz']==0" tabTitle="Sessione d'esame">
                            <div *ngFor="let sessioneEsameRow of sessioneEsame; index as indexElencoSessioni;">
                                <div *ngIf="((sessioneEsameRow.havePrenotatoInTheSessione==1) && (sessioneEsameRow.havePrenotatoInTheSessioneCorso==idCorsoGlobal))" class="courses-curriculum mb-3">
                                    <h3 class="mb-0">{{sessioneEsameRow.dataSessioneFormattata}}</h3>
                                    <span>seleziona le sessione libera con l'orario che preferisci e prenotazione l'esame</span>
                                    <div class="pt-3"></div>
                                    <ul *ngFor="let sessioneEsameRowBody of sessioneEsameRow['body'];">
                                        <li class="mb-3" *ngIf="(sessioneEsameRow.havePrenotatoInTheSessione==1) && sessioneEsameRowBody.havePrenotato>0">
                                            <a target="" (click)="((sessioneEsameRowBody.posti_restanti>0) || (sessioneEsameRowBody.havePrenotato!=0)) ? prenotaSessioneEsame(sessioneEsameRowBody.id_esame_body, sessioneEsameRowBody.data_ora_esame_formattata, sessioneEsameRowBody.havePrenotato) : slotCompleto();" class="d-flex align-items-center" style="cursor:pointer">
                                                <img *ngIf="sessioneEsameRowBody.havePrenotato==0" src="assets/img/clock.png" style="height:26px">
                                                <img *ngIf="sessioneEsameRowBody.havePrenotato!=0" src="assets/img/checked.png" style="height:26px">

                                                <span class="courses-name fw-bolder fs-5 ms-2" style="color:var(--mainColor)">{{sessioneEsameRowBody.data_ora_esame_formattata}}</span>
                                                <div *ngIf="sessioneEsameRowBody.havePrenotato!=0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.havePrenotato!=0" class="questions text-uppercase ms-2">sei prenotato</span>
                                                </div>

                                                <div *ngIf="sessioneEsameRowBody.havePrenotato==0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti==1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posto disponibile</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti>1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posti disponibili</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti<=0" class="duration text-uppercase">slot completo</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="mb-3" *ngIf="(sessioneEsameRow.havePrenotatoInTheSessione==1) && sessioneEsameRowBody.havePrenotato==0">
                                            <a target="" (click)="sessioneBloccataGiaPrenotata();" class="d-flex align-items-center" style="cursor:pointer">
                                                <img src="assets/img/lock.png" style="height:26px">

                                                <span class="courses-name fw-bolder fs-5 ms-2" style="color:var(--mainColor)">{{sessioneEsameRowBody.data_ora_esame_formattata}}</span>

                                                <div *ngIf="sessioneEsameRowBody.havePrenotato==0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti==1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posto disponibile</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti>1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posti disponibili</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti<=0" class="duration text-uppercase">slot completo</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="mb-3" *ngIf="(sessioneEsameRow.havePrenotatoInTheSessione==0)">
                                            <a target="" (click)="((sessioneEsameRowBody.posti_restanti>0) || (sessioneEsameRowBody.havePrenotato!=0)) ? prenotaSessioneEsame(sessioneEsameRowBody.id_esame_body, sessioneEsameRowBody.data_ora_esame_formattata, sessioneEsameRowBody.havePrenotato) : slotCompleto();" class="d-flex align-items-center" style="cursor:pointer">
                                                <img *ngIf="sessioneEsameRowBody.havePrenotato==0" src="assets/img/clock.png" style="height:26px">
                                                <img *ngIf="sessioneEsameRowBody.havePrenotato!=0" src="assets/img/checked.png" style="height:26px">

                                                <span class="courses-name fw-bolder fs-5 ms-2" style="color:var(--mainColor)">{{sessioneEsameRowBody.data_ora_esame_formattata}}</span>
                                                <div *ngIf="sessioneEsameRowBody.havePrenotato!=0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.havePrenotato!=0" class="questions text-uppercase ms-2">sei prenotato</span>
                                                </div>

                                                <div *ngIf="sessioneEsameRowBody.havePrenotato==0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti==1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posto disponibile</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti>1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posti disponibili</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti<=0" class="duration text-uppercase">slot completo</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="(sessioneEsameRow.havePrenotatoInTheSessione==0)" class="courses-curriculum mb-3">
                                    <h3 class="mb-0">{{sessioneEsameRow.dataSessioneFormattata}}</h3>
                                    <span>seleziona le sessione libera con l'orario che preferisci e prenotazione l'esame</span>
                                    <div class="pt-3"></div>
                                    <ul *ngFor="let sessioneEsameRowBody of sessioneEsameRow['body'];">
                                        <li class="mb-3">
                                            <a target="" (click)="((sessioneEsameRowBody.posti_restanti>0) || (sessioneEsameRowBody.havePrenotato!=0)) ? prenotaSessioneEsame(sessioneEsameRowBody.id_esame_body, sessioneEsameRowBody.data_ora_esame_formattata, sessioneEsameRowBody.havePrenotato) : slotCompleto();" class="d-flex align-items-center" style="cursor:pointer">
                                                <img *ngIf="sessioneEsameRowBody.havePrenotato==0" src="assets/img/clock.png" style="height:22px">
                                                <img *ngIf="sessioneEsameRowBody.havePrenotato!=0" src="assets/img/checked.png" style="height:22px">

                                                <span class="courses-name fw-bolder fs-5 ms-2" style="color:var(--mainColor)">{{sessioneEsameRowBody.data_ora_esame_formattata}}</span>
                                                <div *ngIf="sessioneEsameRowBody.havePrenotato!=0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.havePrenotato!=0" class="questions text-uppercase ms-2">sei prenotato</span>
                                                </div>

                                                <div *ngIf="sessioneEsameRowBody.havePrenotato==0" class="courses-meta" style="min-width: 200px;float: right;right: 35px;position: absolute;">
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti==1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posto disponibile</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti>1" class="status text-uppercase">{{sessioneEsameRowBody.posti_restanti}} posti disponibili</span>
                                                    <span *ngIf="sessioneEsameRowBody.posti_restanti<=0" class="duration text-uppercase">slot completo</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ngx-tab>

                        <!-- gestione esame con le sessioni -->
                        <ngx-tab #tabs6 *ngIf="detailQuiz['body'] && isEnabledUser &&  (detailQuiz['isDataLimitata']!=0 || sessioneEsameCorsista.data_ora_esame)" tabTitle="Esame Finale">
                            <div *ngIf="detailQuiz['isDataLimitata']==1">
                                Il TEST per l'esame finale non è ancora attivo, sarà attivo il giorno <strong>{{detailQuiz['dataTimeInizio'] | date:'d/MM/Y'}}</strong> alle ore: <strong>{{detailQuiz['dataTimeInizio'] | date:'H:mm'}}</strong> e termina alle ore: <strong>{{detailQuiz['dataTimeFine'] | date:'H:mm'}}</strong>
                            </div>
                            <div *ngIf="(sessioneEsameCorsista.data_ora_esame && sessioneEsameCorsista.isInLiveEsameCorsista==0 && detailQuiz['isDataLimitata']==0)">
                                Il TEST per l'esame finale non è ancora attivo, sarà attivo il giorno <strong>{{sessioneEsameCorsista.data_ora_esame | date:'d/MM/Y'}}</strong> alle ore: <strong>{{sessioneEsameCorsista.data_ora_esame | date:'H:mm'}}</strong> e termina alle ore: <strong>{{sessioneEsameCorsista.data_ora_esame_end | date:'H:mm'}}</strong>
                            </div>
                            <div *ngIf="detailQuiz['isDataLimitata']==2 && detailQuiz['isSuperatoQuiz']==0">
                                L'accesso al TEST per l'esame finale è terminato il giorno <strong>{{detailQuiz['dataTimeFine'] | date:'d/MM/Y'}}</strong> alle ore: <strong>{{detailQuiz['dataTimeFine'] | date:'H:mm'}}</strong>
                            </div>
                            <div *ngIf="(!(detailQuiz['isDataLimitata']==2) && sessioneEsameCorsista.data_ora_esame && (sessioneEsameCorsista.isInLiveEsameCorsista==2) && detailQuiz['isSuperatoQuiz']==0)">
                                L'accesso al TEST per l'esame finale è terminato il giorno <strong>{{sessioneEsameCorsista.data_ora_esame_end | date:'d/MM/Y'}}</strong> alle ore: <strong>{{sessioneEsameCorsista.data_ora_esame_end | date:'H:mm'}}</strong>
                            </div>
                            <div *ngIf="((sessioneEsameCorsista.isDayEsame==1 && sessioneEsameCorsista.isInLiveEsameCorsista==1) && !(sessioneEsameCorsista.id_esame_type_documento && sessioneEsameCorsista.n_documento))">
                                Esame <strong>non abililtato</strong>, attendere che l'<strong>esaminatore</strong> completa il processo di validazione dei documenti, solo dopo puoi procedere con l'esame.
                                <div *ngIf="detailQuizOnlyTEST.length>1" class="courses-review-comments" style="margin-top: 90px;">
                                    <blockquote style="font-weight: 700!important; font-size: 30px;" class="blockquote">
                                        <img src="assets/img/exam.png" class="me-1" style="width: 42px; padding-right: 2px;">
                                        L'esame è composto da {{detailQuizOnlyTEST.length}} {{detailQuizOnlyTEST.length>1 ? 'moduli' :'modulo'}}, per una durata totale di {{durataEsameTotale}}
                                    </blockquote>
                                    <div *ngFor="let detailQuizTestInner of detailQuizOnlyTEST; index as indexQuizTEST;">
                                        <div class="user-review" style="padding: 20px 0 20px 0px;">
                                            <span class="d-block" style="font-size: 13pt; font-weight: 700;">
                                                {{indexQuizTEST+1}}) {{detailQuizTestInner['name']}}
                                            </span>
                                            <p class="mb-1">{{detailQuizTestInner['descrizione']}}</p>
                                            <span *ngIf="detailQuizTestInner['time_esame_formattata']" style="display: inline-block; background: var(--blackColor); color: var(--whiteColor); border-radius: 3px; padding: 2px 10px 1.5px; font-size: 14px; font-weight: 700; float: right;">DURATA. {{detailQuizTestInner['time_esame_formattata']}}</span>
                                            <span *ngIf="!detailQuizTestInner['time_esame_formattata']" style="display: inline-block; background: var(--blackColor); color: var(--whiteColor); border-radius: 3px; padding: 2px 10px 1.5px; font-size: 14px; font-weight: 700; float: right;">NESSUN LIMITE DI TEMPO</span>
                                            <span style="display: inline-block; background: #e3f1f2; color: #2dbbc4; border-radius: 3px; padding: 2px 10px 1.5px; font-size: 14px; font-weight: 700; margin-right: 5px;">DOMANDE TOTALI. {{detailQuizTestInner['nDomande']}}</span>
                                            <span style="display: inline-block; background: #e3f1f2; color: #2dbbc4; border-radius: 3px; padding: 2px 10px 1.5px; font-size: 14px; font-weight: 700;">MINIMO CORRETTE. {{detailQuizTestInner['corretteDomande']}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(sessioneEsameCorsista.isDayEsame==1 && sessioneEsameCorsista.isInLiveEsameCorsista==1 && sessioneEsameCorsista.id_esame_type_documento && sessioneEsameCorsista.n_documento && (sessioneEsamePin!=sessioneEsameCorsista.pin_esame) && showEsameDiretto==false) && detailQuiz['isSuperatoQuiz']==0">
                                L'<strong>esaminatore</strong> ha registrato con successo i tuoi dati, <strong>puoi procedere con l'esame</strong>.
                                <a *ngIf="sessioneEsameCorsista.isInLiveEsameCorsista==1" target="_blank" (click)="showEsamePINEsame(sessioneEsameCorsista.pin_esame, sessioneEsameCorsista.id_esame_body);"
                                    class="default-btn btn-danger mt-3" style="cursor: pointer; padding-left:30px">
                                    visualizza l'esame
                                </a>
                            </div>
                            <div *ngIf="detailQuiz['isDataLimitata']==3 ||
                                (sessioneEsameCorsista.isDayEsame==1 && sessioneEsameCorsista.isInLiveEsameCorsista==1 && sessioneEsameCorsista.id_esame_type_documento && sessioneEsameCorsista.n_documento && (sessioneEsamePin==sessioneEsameCorsista.pin_esame)) ||
                                showEsameDiretto || detailQuiz['isSuperatoQuiz']==1" class="courses-curriculum">
                                <h3 class="mb-5">{{detailQuiz['name']}}</h3>
                                <ul *ngFor="let detailBody of detailQuiz['body']; index as indexQuiz;">
                                    <li class="mb-5">
                                        <span class="courses-name fw-bolder fs-5" style="color:var(--mainColor)">
                                            {{(indexQuiz+1)+') '+detailBody['domanda']['name']}}
                                            <span *ngIf="detailBody['domanda']['maxChar']>0">(*max caratteri {{detailBody['domanda']['maxChar']}})</span>
                                        </span>
                                        <div *ngIf="detailBody['domanda']['isRispostaLibera']==0">
                                            <ul *ngFor="let detailElencoRisposte of detailBody['elenco_risposte']; index as indexRisposte;">
                                                <li>
                                                    <div class="align-items-center ms-2">
                                                        <input *ngIf="detailQuiz['isSuperatoQuiz']==0" type="radio" isRispostaLibera="0" name="{{detailBody['domanda']['id_domanda']}}" value="{{detailElencoRisposte['risposta']['id_risposta']}}" />
                                                        <span *ngIf="detailQuiz['isSuperatoQuiz']==1" class="courses-name w-75 mt-1 ms-2" style="font-weight: 600;" [ngStyle]="detailQuiz['isSuperatoQuiz']==0 ? {'color': 'var(--blackColor)'} : detailElencoRisposte['isCorrectQuizEffettuati']==1 ? {'color': 'var(--bs-success)'} : detailElencoRisposte['isCorrectQuizEffettuati']==0  ? {'color': 'var(--bs-danger)'} : {'color': 'var(--blackColor)'}">
                                                            <span class="me-1">&bull;</span>{{detailElencoRisposte['risposta']['name']}}
                                                            <span style="color:var(--bs-green)" *ngIf="(((detailQuiz['corsista']['assistenza']==1) && (detailElencoRisposte['isCorrect']==1)) && (detailQuiz['isSuperatoQuiz']==0))">&bull;</span>
                                                        </span>
                                                        <span *ngIf="detailQuiz['isSuperatoQuiz']==0" class="courses-name w-75 mt-1 ms-2" style="font-weight: 600;" [ngStyle]="detailQuiz['isSuperatoQuiz']==0 ? {'color': 'var(--blackColor)'} : detailElencoRisposte['isCorrectQuizEffettuati']==1 ? {'color': 'var(--bs-success)'} : detailElencoRisposte['isCorrectQuizEffettuati']==0  ? {'color': 'var(--bs-danger)'} : {'color': 'var(--blackColor)'}">
                                                            <span style="color:var(--bs-green)" *ngIf="(((detailQuiz['corsista']['assistenza']==1) && (detailElencoRisposte['isCorrect']==1)) && (detailQuiz['isSuperatoQuiz']==0))">
                                                                {{detailElencoRisposte['risposta']['name']}}
                                                            </span>
                                                            <span style="color:var(--bs-black)" *ngIf="(((detailQuiz['corsista']['assistenza']==1) && (detailElencoRisposte['isCorrect']==0)) && (detailQuiz['isSuperatoQuiz']==0))">
                                                                {{detailElencoRisposte['risposta']['name']}}
                                                            </span>
                                                            <span *ngIf="((detailQuiz['corsista']['assistenza']==0) && (detailQuiz['isSuperatoQuiz']==0))">
                                                                {{detailElencoRisposte['risposta']['name']}}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div *ngIf="detailBody['domanda']['isRispostaLibera']==1">
                                            <div class="form-group">
                                                <textarea isRispostaLibera="1" name="{{detailBody['domanda']['id_domanda']}}" cols="30" rows="5" class="form-control" placeholder="scrivi qui la risposta"></textarea>
                                            </div>
                                        </div>
                                   </li>
                                </ul>
                                <div class="btn-box mt-5">
                                    <a id="btnTest" *ngIf="detailQuiz['isSuperatoQuiz']==0" (click)="controllaQuiz()" class="default-btn" style="cursor:pointer"><i class="flaticon-tag"></i>Effettua la verifica dell'esame<span></span></a>
                                    <span *ngIf="detailQuiz['isSuperatoQuiz']==1" class="courses-name fw-normal fs-4" style="color:var(--bs-green)">
                                        hai superato il test il giorno <u><b>{{detailQuiz['quizEffettuati'][0]['data_create'] | date:'d/M/Y'}}</b></u> alle ore <u><b>{{detailQuiz['quizEffettuati'][0]['data_create'] | date:'H:mm'}}</b></u> con una media di <u><b>{{detailQuiz['quizEffettuati'][0]['corrette']}}/{{detailQuiz['quizEffettuati'][0]['numero_domande']}} risposte corrette</b></u></span>
                                </div>
                            </div>
                        </ngx-tab>

                        <!-- gestione esame senza le sessioni -->
                        <ngx-tab #tabs5 *ngIf="detailQuiz['body'] && (ModuloUC_frequenza>=100) && isEnabledUser && detailQuiz['isDataLimitata']==0" tabTitle="Esame Finale">
                            <div *ngIf="detailQuiz['isDataLimitata']==1">
                                Il TEST per l'esame finale non è ancora attivo, sarà attivo il giorno <strong>{{detailQuiz['dataTimeInizio'] | date:'d/MM/Y'}}</strong> alle ore: <strong>{{detailQuiz['dataTimeInizio'] | date:'H:mm'}}</strong> e termina alle ore: <strong>{{detailQuiz['dataTimeFine'] | date:'H:mm'}}</strong>
                            </div>
                            <div *ngIf="detailQuiz['isDataLimitata']==2">
                                L'accesso al TEST per l'esame finale è terminato il giorno <strong>{{detailQuiz['dataTimeFine'] | date:'d/MM/Y'}}</strong> alle ore: <strong>{{detailQuiz['dataTimeFine'] | date:'H:mm'}}</strong>
                            </div>
                            <div  *ngIf="detailQuiz['isDataLimitata']==3 || detailQuiz['isDataLimitata']==0" class="courses-curriculum">
                                <h3 class="mb-5">{{detailQuiz['name']}}</h3>
                                <ul *ngFor="let detailBody of detailQuiz['body']; index as indexQuiz;">
                                    <li class="mb-5">
                                        <span class="courses-name fw-bolder fs-5" style="color:var(--mainColor)">{{(indexQuiz+1)+') '+detailBody['domanda']['name']}}</span>
                                        <ul *ngFor="let detailElencoRisposte of detailBody['elenco_risposte']; index as indexRisposte;">
                                            <li>
                                                <div class="align-items-center ms-2">
                                                    <input *ngIf="detailQuiz['isSuperatoQuiz']==0" type="radio" name="{{detailBody['domanda']['id_domanda']}}" value="{{detailElencoRisposte['risposta']['id_risposta']}}" />
                                                    <span *ngIf="detailQuiz['isSuperatoQuiz']==1" class="courses-name w-75 mt-1 ms-2" style="font-weight: 600;" [ngStyle]="detailQuiz['isSuperatoQuiz']==0 ? {'color': 'var(--blackColor)'} : detailElencoRisposte['isCorrectQuizEffettuati']==1 ? {'color': 'var(--bs-success)'} : detailElencoRisposte['isCorrectQuizEffettuati']==0  ? {'color': 'var(--bs-danger)'} : {'color': 'var(--blackColor)'}">
                                                        <span class="me-1">&bull;</span>{{detailElencoRisposte['risposta']['name']}}
                                                        <span style="color:var(--bs-green)" *ngIf="(((detailQuiz['corsista']['assistenza']==1) && (detailElencoRisposte['isCorrect']==1)) && (detailQuiz['isSuperatoQuiz']==0))">&bull;</span>
                                                    </span>
                                                    <span *ngIf="detailQuiz['isSuperatoQuiz']==0" class="courses-name w-75 mt-1 ms-2" style="font-weight: 600;" [ngStyle]="detailQuiz['isSuperatoQuiz']==0 ? {'color': 'var(--blackColor)'} : detailElencoRisposte['isCorrectQuizEffettuati']==1 ? {'color': 'var(--bs-success)'} : detailElencoRisposte['isCorrectQuizEffettuati']==0  ? {'color': 'var(--bs-danger)'} : {'color': 'var(--blackColor)'}">
                                                        <span style="color:var(--bs-green)" *ngIf="(((detailQuiz['corsista']['assistenza']==1) && (detailElencoRisposte['isCorrect']==1)) && (detailQuiz['isSuperatoQuiz']==0))">
                                                            {{detailElencoRisposte['risposta']['name']}}
                                                        </span>
                                                        <span style="color:var(--bs-black)" *ngIf="(((detailQuiz['corsista']['assistenza']==1) && (detailElencoRisposte['isCorrect']==0)) && (detailQuiz['isSuperatoQuiz']==0))">
                                                            {{detailElencoRisposte['risposta']['name']}}
                                                        </span>
                                                        <span *ngIf="((detailQuiz['corsista']['assistenza']==0) && (detailQuiz['isSuperatoQuiz']==0))">
                                                            {{detailElencoRisposte['risposta']['name']}}
                                                        </span>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                   </li>
                                </ul>
                                <div class="btn-box mt-5">
                                    <a id="btnTest" *ngIf="detailQuiz['isSuperatoQuiz']==0" (click)="controllaQuiz()" class="default-btn" style="cursor:pointer"><i class="flaticon-tag"></i>Effettua la verifica dell'esame<span></span></a>
                                    <span *ngIf="detailQuiz['isSuperatoQuiz']==1" class="courses-name fw-normal fs-4" style="color:var(--bs-green)">
                                        hai superato il test il giorno <u><b>{{detailQuiz['quizEffettuati'][0]['data_create'] | date:'d/M/Y'}}</b></u> alle ore <u><b>{{detailQuiz['quizEffettuati'][0]['data_create'] | date:'H:mm'}}</b></u> con una media di <u><b>{{detailQuiz['quizEffettuati'][0]['corrette']}}/{{detailQuiz['quizEffettuati'][0]['numero_domande']}} risposte corrette</b></u></span>
                                </div>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 mt-xxl-5">
                <div [class]="((isEnabledUser) && (limita_moduloUC==0) && (limiteGiornoAula>0)) ? 'courses-details-info' : 'd-none'" style="margin-bottom: 125px">
                    <div class="">
                        <img src="assets/img/live.png" alt="image">
                        <a href="javascript:;" class="link-btn popup-youtube d-none" (click)="playVideo(videoPreview, 'Anteprima del corso', '', '', '', '', '', '', '0')"></a>
                        <div class="content d-none">
                            <i class="flaticon-play"></i>
                            <span>Anteprima</span>
                        </div>
                    </div>
                    <ul class="info">
                        <li class="">
                            <div class="d-flex justify-content-between align-items-center">
                                Riepilogo dettagli evento
                            </div>
                        </li>
                        <li *ngIf="!isLimiteRaggiunto && limiteGiornoAula" class="">
                            <div class="d-flex justify-content-between align-items-center text-danger">
                                <span class="text-danger ps-0">
                                    <img src="assets/img/chronometer.png" class="me-1" style="width:26px">
                                    Aula termina tra
                                </span>
                                {{labelTempoRestanteLimite}}
                            </div>
                        </li>
                        <li class="d-none">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Docente</span>
                            </div>
                        </li>
                        <li *ngIf="nModuliUC">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="ps-0">
                                    <img src="assets/img/online-course.png" class="me-1" style="width:26px">
                                    {{arrayLive['nome_aula']}}
                                </span>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="ps-0">
                                    <img src="assets/img/event.png" class="me-1" style="width:26px">
                                    Durata
                                </span>
                                <span class="fw-normal">
                                    {{arrayLive['data_ora'] | date: 'HH:mm' }} ÷ {{arrayLive['data_ora_fine_full'] | date: 'HH:mm' }}
                                    ({{arrayLive['durata']}}h)
                                </span>
                            </div>
                        </li>
                        <li *ngFor="let rowDocente of arrayLive['docente']">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="ps-0" style="min-width: 105px">
                                    <img src="assets/img/teacher.png" class="me-1" style="width:26px">
                                    Docente
                                </span>
                                <span class="fw-normal">
                                    {{rowDocente['scheda_docente']['cognome']}} {{rowDocente['scheda_docente']['nome']}}
                                </span>
                            </div>
                        </li>
                    </ul>

                    <div *ngIf="((!isEnabledUser) && (detallCorso['costo']>0) && !showPaypal && detailUser?.length<=0)" class="btn-box mb-2">
                        <a (click)="checkout();" class="default-btn disabled" style="cursor: pointer;">
                            <img src="assets/img/PayPal-Express.png" class="me-1" style="width:42px">
                            Acquista il corso<span></span>
                        </a>
                    </div>
                    <div *ngIf="showPaypal" class="btn-box mb-2">
                        <a (click)="annullaAcquisto();" class="default-btn btn-danger disabled" style="cursor: pointer;">
                            <img src="assets/img/PayPal-Express.png" class="me-1" style="width:42px">
                            Annulla Acquisto<span></span>
                        </a>
                    </div>

                    <div class="row ps-4 pe-4 bg-white pt-4 m-3" style="border-radius: 20px;" *ngIf="showPaypal">
                        <!-- paypal component -->
                        <paypal class="col-12" *ngIf="!isTransactionComplete" [amount]="cartTotalPrice" (approve)="paymentApprove($event)"></paypal>
                        <h1 class="py-5" *ngIf="isTransactionComplete">Grazie per il tuo acquisto</h1>
                    </div>

                    <div [class]="((!isEnabledUser) && (detallCorso['costo']>0)) ? 'btn-box mt-0' : 'btn-box'">
                        <a id="11072023" *ngIf="isInLive==1 && isLimiteRaggiunto==false" target="_blank" [attr.href]="isInLive ? (limita_moduloUC==0) ? isInLive && isInLiveLinkAula ? isInLiveLinkAula : null : null : 'contatti'" (click)="isInLive && isInLiveLinkAula ? (limita_moduloUC==0) ? entraInAula() : selectTabModuliUC(isInLive==1) : (limita_moduloUC==1) ? selectTabModuliUC(isInLive==1) : isInLive ? entraInAulaMeet(): ''"
                            [class]="isInLive && isInLiveLinkAula ? 'default-btn' : 'default-btn'" style="cursor: pointer;">
                            <img *ngIf="isInLive && isInLiveLinkAula=='' && limita_moduloUC==0"  src="assets/img/meet.png" class="me" style="width:36px; padding:2px">
                            <i *ngIf="isInLive && isInLiveLinkAula!=''" [class]="(isInLive) ? (limita_moduloUC==0) ? 'flaticon-tutorials' : 'flaticon-agenda' : 'flaticon-shopping-cart'"></i>
                            {{isInLive ? (limita_moduloUC==0) ? isInLive && isInLiveLinkAula ? 'Entra in Aula' : 'Entra in Aula': 'Entra in FAD' : 'FAD non attiva'}}<span></span>
                        </a>
                        <a *ngIf="isInLive==3 && isLimiteRaggiunto==false && !linkEsterno" target="_blank" [attr.href]="null" (click)="selectTabModuliUC(0)" class="default-btn" style="cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            Corso Abilitato<span></span>
                        </a>
                        <a *ngIf="isInLive==3 && isLimiteRaggiunto==false && linkEsterno" target="_blank" [attr.href]="linkEsterno" class="default-btn" style="cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            Accedi al corso<span></span>
                        </a>
                        <a *ngIf="(isInLive==4 || isInLive==2) && isLimiteRaggiunto==false" target="_blank" [attr.href]="null" (click)="limita_moduloUC==0 ? selectAula() : selectTabModuliUC(0); noFADAttiva('0')" class="default-btn" style="background-color: var(--bs-red); cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            {{limita_moduloUC==0 ? 'Aula non attiva' : 'FAD non attiva' }}<span></span>
                        </a>
                        <a *ngIf="isLimiteRaggiunto==true" class="default-btn" (click)="limiteRaggiunto()" style="background-color: var(--bs-red); cursor:pointer">
                            Limite giornaliero raggiunto<span></span>
                        </a>
                        <a routerLink="/contatti" *ngIf="!isInLive" [class]="!isInLive ? 'default-btn' : 'd-none'">
                            <img src="assets/img/checklist.png" class="me" style="width:42px; padding:2px">
                            Richiedi informazioni<span></span>
                        </a>
                    </div>
                    <div class="btn-box mt-2 d-none">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Effettua Test<span></span></a>
                    </div>
                    <div class="courses-share mt-3">
                        <div class="share-info" style="cursor:default">
                            <span *ngIf="isLimiteRaggiunto==false" class="text-dark fw-normal">
                                <!--{{isInLive ? (limita_moduloUC==0) ? isInLive && isInLiveLinkAula ? (isInLive!=2 && isInLive!=4) ? 'l\'aula è attiva, entra subito per confermare la tua presenza' : 'la sessione dell\'accesso in aula è scaduto, hai saltato la lezione' : limita_moduloUC==0 && isInLive==4 ? 'non ci sono aula attive nella giornata di oggi, consulta l\'elenco date' : limita_moduloUC==0 && isInLive==3 ? 'puoi visualizzare le video lezione del corso, visualizza l\'elenco nella sezione Moduli UC' :'non è stato ancora creato il link per connetterti in aula virtuale, riprova più tardi' : isInLive==4 || isInLive==2 ? 'non puoi accedere ai Moduli UC in questo giorno o fascia d\'orario, consulta l\'elenco date per vedere quando puoi accedere' : 'accedi alla lista delle video-lezioni, apprendi e migliora il tuo livello con i video presenti in ogni Modulo UC' : 'Direttamente da casa o dal tuo ufficio, comodamente seduto, senza spostarti in auto, in moto o con i mezzi pubblici'}}-->
                                {{isInLive ? (limita_moduloUC==0) ? isInLive && isInLiveLinkAula ? 'l\'aula è attiva, entra subito per confermare la tua presenza' : 'l\'aula è attiva, entra subito per confermare la tua presenza': 'l\'aula è attiva, entra subito per confermare la tua presenza' : 'l\'aula non è attiva, controlla il calendario degli eventi'}}<span></span>

                            </span>
                            <span *ngIf="isLimiteRaggiunto==true" class="text-dark fw-normal" (click)="selectAula();">
                                hai raggiunto il limite giornaliero per la visualizzazione dei video, consulta l'<b style="cursor: pointer;">elenco date</b> per vedere quando puoi accedere per visualizzare gli altri video
                             </span>
                            <p class="d-none">Condividi il corso <i class="flaticon-share"></i></p>
                            <ul class="social-link d-none">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="courses-details-info">
                    <div class="image">
                        <img class="imgMiur" *ngIf="isMiur==true" src="assets/img/miur.png" style="bottom: 20px; left:0px">
                        <img class="imgMiur" style="bottom:120px; left: 0px;" *ngIf="isMim==true && isMiur==true" src="assets/img/mim.png">
                        <img class="imgMiur" style="bottom: 20px; left: 0px;" *ngIf="isMim==true && isMiur==false" src="assets/img/mim.png">
                    </div>

                    <div class="image">
                        <img src="{{urlImage+'preview.jpg'}}" alt="image" class="d-none">
                        <a href="javascript:;" class="link-btn popup-youtube d-none" (click)="playVideo(videoPreview, 'Anteprima del corso', '', '', '', '', '', '', '0')"></a>
                        <div class="content d-none">
                            <i class="flaticon-play"></i>
                            <span>Anteprima</span>
                        </div>
                    </div>
                    <ul class="info">

                        <li class="">
                            <div class="d-flex justify-content-between align-items-center">
                                Riepilogo e dettaglio del corso
                            </div>
                        </li>
                        <li *ngIf="!isLimiteRaggiunto && limiteGiorno" class="">
                            <div class="d-flex justify-content-between align-items-center" style="color:var(--mainColor)">
                                <span class="text-danger"><i class="flaticon-teacher"></i> <span style="color:var(--mainColor)" class="p-0">Tempo Sessione</span></span>
                                {{labelTempoRestanteSessione}}
                            </div>
                        </li>
                        <li *ngIf="!isLimiteRaggiunto && limiteGiorno" class="">
                            <div class="d-flex justify-content-between align-items-center text-danger">
                                <span class="text-danger"><i class="flaticon-teacher text-danger"></i> Limite giornaliero</span>
                                {{labelTempoRestanteLimite}}
                            </div>
                        </li>
                        <li *ngIf="((detallCorso['haveBuy']=='1') && detailQuiz['isSuperatoQuiz']==0) && ((sessioneEsameCorsista.isDayEsame==1 && sessioneEsameCorsista.isInLiveEsameCorsista==1) || detailQuiz['isDataLimitata']==3)" class="">
                            <div class="d-flex justify-content-between align-items-center text-danger">
                                <span class="text-danger ps-0">
                                    <img src="assets/img/chronometer.png" class="me-1" style="width:26px">
                                    TEST termina tra
                                </span>
                                {{labelTempoRestanteLimiteTEST}}
                            </div>
                        </li>
                        <li *ngIf="((detallCorso['haveBuy']=='0') && (detallCorso['costo']>300) && canShowPagamentoRate)">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i><img src="assets/img/money.png" style="width:32px"></i>&nbsp;&nbsp;Pagamento</span>
                                <div [innerHTML]=textShowPagamentoRate></div>
                            </div>
                        </li>
                        <li *ngIf="((detallCorso['haveBuy']=='0') && (costoCorsoScontato!=''))" class="price">
                            <div class="d-flex justify-content-between align-items-center text-danger">
                                <span><i><img src="assets/img/offer1.png" style="width:32px"></i>
                                    <div class="ms-2">In Offerta</div></span>
                                € {{costoCorsoScontato}}
                            </div>
                        </li>
                        <li *ngIf="((detallCorso['haveBuy']=='0') && (detallCorso['costo']>0))" class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Prezzo</span>
                                <s *ngIf="((detallCorso['haveBuy']==0) && (costoCorsoScontato!=''))">€ {{costoCorso}}</s>
                                <div *ngIf="((detallCorso['haveBuy']==0) && (costoCorsoScontato==''))">€ {{costoCorso}}</div>
                            </div>
                        </li>
                        <li class="d-none">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Docente</span>
                                none
                            </div>
                        </li>
                        <li *ngIf="detallCorso['durataNumero']!='0'">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-certification"></i>  {{detallCorso['categoria']}}</span>
                            </div>
                        </li>
                        <li *ngIf="detallCorso['durataNumero']!='0'">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Durata</span>
                                {{detallCorso['durata']}}
                            </div>
                        </li>
                        <li *ngIf="detallCorso['cfu']>0">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-certification-1"></i> Crediti</span>
                                {{detallCorso['cfu']}} CFU
                            </div>
                        </li>
                        <li *ngIf="nModuliUC">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> {{nModuliUC}} Moduli UC</span>
                                {{nParagrafi}} paragrafi
                            </div>
                        </li>
                        <li *ngIf="sessioneEsameCorsista.isInLiveEsameCorsista==1 && detailQuiz['isSuperatoQuiz']==0">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> PIN MEET</span>
                                {{sessioneEsameCorsista.pin_meet}}
                            </div>
                        </li>
                        <li *ngIf="sessioneEsameCorsista.isInLiveEsameCorsista==1 && sessioneEsameCorsista.id_esame_type_documento && sessioneEsameCorsista.n_documento && detailQuiz['isSuperatoQuiz']==0">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> <span class="text-danger p-0">PIN ESAME</span></span>
                                <span class="text-danger p-0">{{sessioneEsameCorsista.pin_esame}}</span>
                            </div>
                        </li>
                        <li *ngIf="detailQuiz['isSuperatoQuiz']==1">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-checkmark"></i> Voto</span>
                                <span style="font-weight: 600; color: var(--bs-success);">
                                    {{detailQuiz['quizEffettuati'][0]['corrette']}} / {{detailQuiz['quizEffettuati'][0]['numero_domande']}}
                                </span>
                            </div>
                        </li>
                        <li class="border-0 d-none">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Accesso</span>
                                Illimitato
                            </div>
                        </li>
                        <li *ngIf="detailQuiz['isSuperatoQuiz']==1" class="border-0">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i><img src="assets/img/certificate.png" style="width: 26px"></i> Attestato</span>
                                <span class="fw-bold text-danger" *ngIf="this.detailUser['canDownloadAttestato']==0">non abilitato</span>
                                <span class="fw-bold text-success" *ngIf="this.detailUser['canDownloadAttestato']==1">disponibile</span>
                            </div>
                        </li>
                    </ul>

                    <div *ngIf="((detallCorso['haveBuy']=='0') && (detallCorso['costo']>0) && !showPaypal)" class="btn-box mb-2">
                        <a (click)="checkout();" class="default-btn disabled" style="cursor: pointer;">
                            <img src="assets/img/PayPal-Express.png" class="me-1" style="width:42px">
                            Acquista il corso<span></span>
                        </a>
                    </div>
                    <div *ngIf="showPaypal" class="btn-box mb-2">
                        <a (click)="annullaAcquisto();" class="default-btn btn-danger disabled" style="cursor: pointer;">
                            <img src="assets/img/PayPal-Express.png" class="me-1" style="width:42px">
                            Annulla Acquisto<span></span>
                        </a>
                    </div>

                    <div class="row ps-4 pe-4 bg-white pt-4 m-3" style="border-radius: 20px;" *ngIf="showPaypal">
                        <!-- paypal component -->
                        <paypal class="col-12" *ngIf="!isTransactionComplete" [amount]="cartTotalPrice" [descrizione_prodotto]="detallCorso.titolo" (approve)="paymentApprove($event)"></paypal>
                        <h1 class="py-5" *ngIf="isTransactionComplete">Grazie per il tuo acquisto</h1>
                    </div>

                    <div [class]="((!isEnabledUser) && (detallCorso['costo']>0)) ? 'btn-box mt-0' : 'btn-box'">
                        <a *ngIf="sessioneEsameCorsista.isInLiveEsameCorsista==1 && detailQuiz['isSuperatoQuiz']==0" target="_blank" href="{{sessioneEsameCorsista.link_meet}}"
                            class="default-btn" style="cursor: pointer;" (click)="entraInMeetEsame(sessioneEsameCorsista.id_esame_body);">
                            Entra in MEET (esame)
                        </a>
                        <a *ngIf="detailQuiz['isSuperatoQuiz']==0 && this.arrayLive.hasOwnProperty('isToday')==false && this.detallCorso['haveBuy'] && sessioneEsameCorsista.isInLiveEsameCorsista!=1" target="_blank" (click)="selectTabModuliUC(2)"
                            class="default-btn" style="cursor: pointer;">
                            Visualizza il corso
                        </a>
                        <a id="11072023" *ngIf="isInLive==1 && isLimiteRaggiunto==false && (tempoRestanteLimiteTEST<=0) && limita_moduloUC==1 && isFAD_Always==false" target="_blank" [attr.href]="isInLive ? (limita_moduloUC==0) ? isInLive && isInLiveLinkAula ? isInLiveLinkAula : null : null : 'contatti'" (click)="isInLive && isInLiveLinkAula ? (limita_moduloUC==0) ? entraInAula() : selectTabModuliUC(isInLive==1) : (limita_moduloUC==1) ? selectTabModuliUC(isInLive==1) : isInLive ? noAulaAttiva(): ''"
                            [class]="isInLive && isInLiveLinkAula ? 'default-btn' : 'default-btn'" style="cursor: pointer;">
                            <i [class]="(isInLive) ? (limita_moduloUC==0) ? 'flaticon-tutorials' : 'flaticon-agenda' : 'flaticon-shopping-cart'"></i>
                            {{isInLive ?
                                        (limita_moduloUC==0) ?
                                                            isInLive && isInLiveLinkAula ?
                                                                                        'Entra in Aula' : 'Aula non attiva':
                                                                                        isFAD_Always ? 'FAD: accesso libero' : 'Entra in FAD' :
                                        'FAD non attiva'}}<span></span>
                        </a>
                        <a id="11072023" *ngIf="isFAD_Always==true" target="_blank" [attr.href]="null" (click)="selectTabModuliUC(isInLive==1)"
                            [class]="isInLive && isInLiveLinkAula ? 'default-btn' : 'default-btn'" style="cursor: pointer;">
                            <i [class]="flaticon-agenda"></i>
                            FAD: accesso libero<span></span>
                        </a>
                        <a id="11072023" *ngIf="!isInLive && this.detallCorso['haveBuy']" target="_blank" [attr.href]="null"
                            class="default-btn" style="cursor: pointer; background-color: var(--bs-danger)">
                            <i [class]="flaticon-agenda"></i>
                            Non ci sono eventi per oggi<span></span>
                        </a>
                        <a *ngIf="detailQuiz['body'] && (ModuloUC_frequenza>=100) && isEnabledUser && (tempoRestanteLimiteTEST>0)" class="default-btn d-none" style="cursor:pointer" (click)="showEsame();">
                            <img src="assets/img/exam.png" class="me-1" style="width:42px; padding-right:2px">
                            Esame Abilitato<span></span>
                        </a>
                        <a *ngIf="isInLive==3 && isLimiteRaggiunto==false && detailQuiz['isSuperatoQuiz']==0 && !linkEsterno && moduliUC?.length>0" target="_blank" [attr.href]="null" (click)="selectTabModuliUC(0)" class="default-btn" style="cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            Corso Abilitato<span></span>
                        </a>
                        <a *ngIf="(isInLive==3 && linkEsterno)" target="_blank" [attr.href]="linkEsterno" class="default-btn" style="cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            Accedi al corso<span></span>
                        </a>
                        <a *ngIf="(isInLive==3 && !linkEsterno && moduliUC?.length<=0)" class="default-btn" style="cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            Accedi al corso<span></span>
                        </a>
                        <a *ngIf="detailQuiz['isSuperatoQuiz']==1 && this.detailUser['canDownloadAttestato']==1" target="_blank" [attr.href]="null" (click)="downloadAttestato(this.detailUser['id_corsista'],idCorsoGlobal, this.arrayLive['id_corso_svolgimento'])" class="default-btn" style="cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            Download Attestato<span></span>
                        </a>
                        <a *ngIf="(isInLive==4 || isInLive==2) && isLimiteRaggiunto==false && detailQuiz['isSuperatoQuiz']==0" target="_blank" [attr.href]="null" (click)="limita_moduloUC==0 ? selectAula() : selectTabModuliUC(0); noFADAttiva('0')" class="default-btn" style="background-color: var(--bs-red); cursor:pointer">
                            <i class="flaticon-tutorials"></i>
                            {{limita_moduloUC==0 ? 'Aula non attiva' : 'FAD non attiva' }}<span></span>
                        </a>
                        <a *ngIf="(isInLive==5)" target="_blank" [attr.href]="null" class="default-btn" style="background-color: var(--bs-red); cursor:pointer">
                            Inizio alle ore {{arrayLive['ora_formattata']}}<span></span>
                        </a>
                        <a *ngIf="isLimiteRaggiunto==true" class="default-btn" (click)="limiteRaggiunto()" style="background-color: var(--bs-red); cursor:pointer">
                            Limite giornaliero raggiunto<span></span>
                        </a>
                        <a [href]="numeroTelefono" *ngIf="!isInLive && (numeroTelefono!='') && this.detallCorso['haveBuy']=='0'" [class]="!isInLive ? 'default-btn' : 'd-none'">
                            <img src="assets/img/telephone.png" class="me-1" style="width:42px; padding:2px">
                            Chiama per info<span></span>
                        </a>
                         <a routerLink="/contatti" *ngIf="!isInLive && this.detallCorso['haveBuy']=='0'" [class]="!isInLive ? 'default-btn mt-2' : 'd-none'" style="background-color: var(--mainColor); color: white; border: 0px;">
                            <img src="assets/img/checklist.png" class="" style="width:42px; padding:2px;">
                            Richiedi info<span></span>
                        </a>
                    </div>
                    <div class="btn-box mt-2 d-none">
                        <a routerLink="/contatti" class="default-btn"><i class="flaticon-tag"></i>Effettua Test<span></span></a>
                    </div>
                    <div *ngIf="limiteGiornoAula==0" class="courses-share mt-3">
                        <div class="share-info" style="cursor:default">
                            <span *ngIf="sessioneEsameCorsista.isInLiveEsameCorsista==1 && detailQuiz['isSuperatoQuiz']==0" class="text-dark fw-normal">
                                aula MEET abilitata per la sessione d'esame, entra nella MEET ed inserisci il PIN.
                            </span>
                            <span *ngIf="!this.arrayLive['isToday']==true && detailQuiz['isSuperatoQuiz']==0 && this.detailUser['canDownloadAttestato']==0 && sessioneEsameCorsista.isInLiveEsameCorsista!=1" class="text-dark fw-normal">
                                corso ABILITATO, puoi accedere al contenuto in qualsiasi momento senza limiti di tempo.
                            </span>
                            <span *ngIf="isLimiteRaggiunto==false && detailQuiz['isSuperatoQuiz']==0 && this.arrayLive['isToday']" class="text-dark fw-normal">
                                {{isInLive ? (limita_moduloUC==0) ? isInLive && isInLiveLinkAula ? (isInLive!=2 && isInLive!=4) ? 'l\'aula è attiva, entra subito per confermare la tua presenza' : 'la sessione dell\'accesso in aula è scaduto, hai saltato la lezione' : limita_moduloUC==0 && isInLive==4 ? 'non ci sono aula attive nella giornata di oggi, consulta l\'elenco date' : limita_moduloUC==0 && isInLive==3 ? 'puoi visualizzare le video lezione del corso, visualizza l\'elenco nella sezione Moduli UC' :'non è stato ancora creato il link per connetterti in aula virtuale, riprova più tardi' : isInLive==4 || isInLive==2 ? 'non puoi accedere ai Moduli UC in questo giorno o fascia d\'orario, consulta l\'elenco date per vedere quando puoi accedere' : 'accedi alla lista delle video-lezioni, apprendi e migliora il tuo livello con i video presenti in ogni Modulo UC' : 'Direttamente da casa o dal tuo ufficio, comodamente seduto, senza spostarti in auto, in moto o con i mezzi pubblici'}}
                            </span>
                            <span *ngIf="detailQuiz['isSuperatoQuiz']==0 && this.arrayLive.hasOwnProperty('isToday')==false && this.detallCorso['haveBuy'] && sessioneEsameCorsista.isInLiveEsameCorsista!=1" class="text-dark fw-normal">
                                corso ABILITATO, puoi accedere al contenuto in qualsiasi momento senza limiti di tempo.
                            </span>
                            <span *ngIf="detailQuiz['isSuperatoQuiz']==1 && this.detailUser['canDownloadAttestato']==0" class="text-dark fw-normal">
                                <strong>non sei abilitato</strong> al download dell'attestato, contatta l'amministrazione per ulteriori informazioni.
                            </span>
                            <span *ngIf="detailQuiz['isSuperatoQuiz']==1 && this.detailUser['canDownloadAttestato']==1" class="text-dark fw-normal">
                                complimenti! il corso è stato frequentato e superato il test finale con successo
                            </span>
                            <span *ngIf="isLimiteRaggiunto==true" class="text-dark fw-normal" (click)="selectAula();">
                                hai raggiunto il limite giornaliero per la visualizzazione dei video, consulta l'<b style="cursor: pointer;">elenco date</b> per vedere quando puoi accedere per visualizzare gli altri video
                             </span>
                            <p class="d-none">Condividi il corso <i class="flaticon-share"></i></p>
                            <ul class="social-link d-none">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div *ngIf="detailAcquisto" class="courses-details-info mt-3">
                    <ul class="info">
                        <li class="">
                            <div class="d-flex justify-content-between align-items-center">
                                Dettaglio del Pagamento
                            </div>
                        </li>
                        <li >
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i><img src="assets/img/wallet.png" style="width: 26px"></i> Pagamento</span>
                                <div class="text-capitalize">{{detailAcquisto['metodo']}}</div>
                            </div>
                        </li>
                        <li *ngIf="detailAcquisto['id_purchase']" class="border-0">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i><img src="assets/img/shield.png" style="width: 26px"></i> Transizione</span>
                                {{detailAcquisto['id_purchase']}}
                            </div>
                        </li>
                        <li *ngIf="detailAcquisto['date_create']" class="border-0">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i><img src="assets/img/calendar.png" style="width: 26px"></i> Data</span>
                                {{detailAcquisto['date_create'] | date: 'dd/MM/YYYY - HH:mm'}}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Altri corsi che potrebbero piacerti</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let propostaCorso of nPropostaCorsi">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}" class="d-block image">
                            <img *ngIf="propostaCorso['corso']['isMiur']==1" src="assets/img/miur.png" style="height: 50px; position: absolute; bottom: 20px; left: 25px;">
                            <img *ngIf="propostaCorso['corso']['isMim']==1" src="assets/img/mim.png" style="height: 50px; position: absolute; bottom: 20px; right: 25px;">
                            <img src="{{urlImage+propostaCorso['corso']['image']}}" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3><a target="_parent" href="/dettaglio-corso/{{propostaCorso['corso']['tag']}}">{{propostaCorso['corso']['titolo']}}</a></h3>
                        <p style="overflow: hidden;
                                  text-overflow: ellipsis;
                                  display: -webkit-box;
                                  -webkit-line-clamp: 3;
                                  -webkit-box-orient: vertical">{{propostaCorso['corso']['descrizione']}}</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li *ngIf="propostaCorso['corso']['durata'].durata_numero>0" class="fw-bold"><i class='flaticon-time-left'></i>
                                {{propostaCorso['corso']['durata'].durata_numero}}{{propostaCorso['corso']['durata'].durata_label=='h' ? 'h' : ' '+propostaCorso['corso']['durata'].durata_label}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
