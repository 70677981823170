import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from "ngx-accordion";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { LoginComponent } from './components/pages/login/login.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';

import { PremiumAccessComponent } from './components/common/premium-access/premium-access.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { ProfileAuthenticationComponent } from './components/pages/profile-authentication/profile-authentication.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { ElearningBannerComponent } from './components/pages/elearning-school/elearning-banner/elearning-banner.component';
import { FeaturesStyleOneComponent } from './components/common/features-style-one/features-style-one.component';
import { ElearningAboutComponent } from './components/pages/elearning-school/elearning-about/elearning-about.component';
import { ViewAllCoursesComponent } from './components/common/view-all-courses/view-all-courses.component';
import { GetInstantCoursesComponent } from './components/common/get-instant-courses/get-instant-courses.component';
import { FunfactsFeedbackComponent } from './components/common/funfacts-feedback/funfacts-feedback.component';
import { VideoComponent } from './components/common/video/video.component';
import { CoursesComponent } from './components/common/courses/courses.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { ViewAllCoursesTwoComponent } from './components/common/view-all-courses-two/view-all-courses-two.component';
import { SloganComponent } from './components/common/slogan/slogan.component';
import { InstructorComponent } from './components/common/instructor/instructor.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { PremiumAccessTwoComponent } from './components/common/premium-access-two/premium-access-two.component';
import { FunfactsStyleOneComponent } from './components/common/funfacts-style-one/funfacts-style-one.component';
import { FeaturesStyleTwoComponent } from './components/common/features-style-two/features-style-two.component';
import { InstructorStyleTwoComponent } from './components/common/instructor-style-two/instructor-style-two.component';
import { TopCategoriesComponent } from './components/common/top-categories/top-categories.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FunfactsStyleTwoComponent } from './components/common/funfacts-style-two/funfacts-style-two.component';
import { FeedbackWithBgComponent } from './components/common/feedback-with-bg/feedback-with-bg.component';
import { HowToApplyComponent } from './components/common/how-to-apply/how-to-apply.component';
import { FreeTrialComponent } from './components/common/free-trial/free-trial.component';
import { UpcomingEventsComponent } from './components/common/upcoming-events/upcoming-events.component';
import { BoxesComponent } from './components/common/boxes/boxes.component';
import { OverviewComponent } from './components/common/overview/overview.component';
import { TrainingComponent } from './components/common/training/training.component';
import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { DownloadSyllabusComponent } from './components/common/download-syllabus/download-syllabus.component';
import { FlexiblePricingComponent } from './components/common/flexible-pricing/flexible-pricing.component';
import { SubscribeStyleTwoComponent } from './components/common/subscribe-style-two/subscribe-style-two.component';
import { ExperienceComponent } from './components/common/experience/experience.component';
import { LifestyleComponent } from './components/common/lifestyle/lifestyle.component';
import { ProgramComponent } from './components/common/program/program.component';
import { ServicesComponent } from './components/common/services/services.component';
import { SubscribeStyleThreeComponent } from './components/common/subscribe-style-three/subscribe-style-three.component';
import { SubscribeStyleFourComponent } from './components/common/subscribe-style-four/subscribe-style-four.component';
import { SelectedAgesComponent } from './components/common/selected-ages/selected-ages.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { OnlinePlatformComponent } from './components/common/online-platform/online-platform.component';
import { FeaturedComponent } from './components/common/featured/featured.component';
import { WhyChooseUsComponent } from './components/common/why-choose-us/why-choose-us.component';
import { FunfactsStyleThreeComponent } from './components/common/funfacts-style-three/funfacts-style-three.component';
import { FeedbackStyleThreeComponent } from './components/common/feedback-style-three/feedback-style-three.component';
import { ExpertTrainerComponent } from './components/common/expert-trainer/expert-trainer.component';
import { ApplyInstructorComponent } from './components/common/apply-instructor/apply-instructor.component';
import { TeacherRegisterComponent } from './components/common/teacher-register/teacher-register.component';
import { SuccessPeopleComponent } from './components/common/success-people/success-people.component';
import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component';
import { LearningBannerComponent } from './components/pages/learning-management/learning-banner/learning-banner.component';
import { FeaturesStyleFourComponent } from './components/common/features-style-four/features-style-four.component';
import { LearningCoursesComponent } from './components/pages/learning-management/learning-courses/learning-courses.component';
import { LearningAboutComponent } from './components/pages/learning-management/learning-about/learning-about.component';
import { TestimonialsStyleTwoComponent } from './components/common/testimonials-style-two/testimonials-style-two.component';
import { GetInstantCoursesTwoComponent } from './components/common/get-instant-courses-two/get-instant-courses-two.component';
import { PartnerSliderComponent } from './components/common/partner-slider/partner-slider.component';
import { VideoStyleTwoComponent } from './components/common/video-style-two/video-style-two.component';
import { FunfactsStyleFourComponent } from './components/common/funfacts-style-four/funfacts-style-four.component';
import { LearningBlogComponent } from './components/pages/learning-management/learning-blog/learning-blog.component';
import { LearningViewCoursesComponent } from './components/pages/learning-management/learning-view-courses/learning-view-courses.component';
import { LearningPremiumAccessComponent } from './components/pages/learning-management/learning-premium-access/learning-premium-access.component';

import { ReadComponent } from './components/products/read/read.component';
import { DetailComponent } from './components/products/detail/detail.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ValiditaAttestatoComponent } from './components/pages/validita-attestato/validita-attestato';
import { Eipass } from './components/pages/eipass/eipass';
import { Pekit } from './components/pages/pekit/pekit';
import { LIM } from './components/pages/lim/lim';
import { Inglese } from './components/pages/inglese/inglese';
import { Coding } from './components/pages/coding/coding';
import { Tablet } from './components/pages/tablet/tablet';
import { ParGol } from './components/pages/pargol/par-gol';
import { GestioneAutonoma } from './components/pages/gestione-autonoma/gestione-autonoma';
import { SliderCarouselHome } from './components/pages/slider-carousel-home/slider-carousel-home.component';
import { JitsiComponent } from './jitsi/jitsi.component';

import { PersonaleATA } from './components/pages/personale-ata/personale-ata';

import { RouterModule } from '@angular/router';
import {NgxMaskModule} from 'ngx-mask'
import { InputMaskDirective } from './input-mask.directive';

import { PaypalComponent } from './components/common/paypal/paypal.component';


@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ElearningSchoolComponent,
    PartnerComponent,
    ContactUsComponent,
    PremiumAccessComponent,
    SubscribeComponent,
    ProfileAuthenticationComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    ProfileComponent,
    ElearningBannerComponent,
    FeaturesStyleOneComponent,
    ElearningAboutComponent,
    ViewAllCoursesComponent,
    GetInstantCoursesComponent,
    FunfactsFeedbackComponent,
    VideoComponent,
    CoursesComponent,
    BlogComponent,
    ViewAllCoursesTwoComponent,
    SloganComponent,
    InstructorComponent,
    TestimonialsComponent,
    PremiumAccessTwoComponent,
    FunfactsStyleOneComponent,
    FeaturesStyleTwoComponent,
    InstructorStyleTwoComponent,
    TopCategoriesComponent,
    FeedbackComponent,
    FunfactsStyleTwoComponent,
    FeedbackWithBgComponent,
    HowToApplyComponent,
    FreeTrialComponent,
    UpcomingEventsComponent,
    BoxesComponent,
    OverviewComponent,
    TrainingComponent,
    FeedbackStyleTwoComponent,
    DownloadSyllabusComponent,
    FlexiblePricingComponent,
    SubscribeStyleTwoComponent,
    ExperienceComponent,
    LifestyleComponent,
    ProgramComponent,
    ServicesComponent,
    SubscribeStyleThreeComponent,
    SubscribeStyleFourComponent,
    SelectedAgesComponent,
    AppDownloadComponent,
    OnlinePlatformComponent,
    FeaturedComponent,
    WhyChooseUsComponent,
    FunfactsStyleThreeComponent,
    FeedbackStyleThreeComponent,
    ExpertTrainerComponent,
    ApplyInstructorComponent,
    TeacherRegisterComponent,
    SuccessPeopleComponent,
    LearningManagementComponent,
    LearningBannerComponent,
    FeaturesStyleFourComponent,
    LearningCoursesComponent,
    LearningAboutComponent,
    TestimonialsStyleTwoComponent,
    GetInstantCoursesTwoComponent,
    PartnerSliderComponent,
    VideoStyleTwoComponent,
    FunfactsStyleFourComponent,
    LearningBlogComponent,
    LearningViewCoursesComponent,
    LearningPremiumAccessComponent,
    ReadComponent,
    DetailComponent,
    ValiditaAttestatoComponent,
    InputMaskDirective,
    Eipass,
    Pekit,
    LIM,
    Inglese,
    Coding,
    Tablet,
    ParGol,
    GestioneAutonoma,
    SliderCarouselHome,
    PaypalComponent,
    JitsiComponent,
    PersonaleATA,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    AccordionModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    SweetAlert2Module.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [],
  exports: [
    InputMaskDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
